import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Filter from '../Filters/Filter';
import useFetch from '../../../useFetch';
import Paginate from '../../../Paginate';
import DeletePopup from '../DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Form, FormCheck, Image, Modal, Table } from 'react-bootstrap';
import ActionPopup from '../ActionPopup';
import UpdateStatus from './UpdateStatus';
import UpdateComments from './UpdateComments';
import { useSelector } from 'react-redux';
import Footer from '../../Footer';

const Index = () => {
    document.title = "Truck List | Smart Trucking Services";
    
    let navigate = useNavigate();
    const { user } = useSelector(state => state.user);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [listType, setListType] = useState(null);

    const [updateStatusItem, setUpdateStatusItem] = useState(null);
    const [updateCommentsItem, setUpdateCommentsItem] = useState(null);
    
    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/trucks/'+(listType ?? 'active'));
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [sort, setSort] = useState({column: 'legal', type: 'asc'})
    const [apiUrl, setApiUrl] = useState(url);

    const filters = {
        action: apiUrl,
        method: 'GET',
        fields: [
            {
                label: 'Legal Name',
                type: 'input',
                name: 'legal',
            },
            {
                label: 'Plate No',
                type: 'input',
                name: 'plate',
            },
            {
                label: 'Unit No',
                type: 'input',
                name: 'unit',
            },
            {
                label: 'Vin No',
                type: 'input',
                name: 'vin',
            },
            {
                label: 'Emission Expiry Start',
                type: 'input',
                subType: 'date',
                name: 'emission_start',
            },
            {
                label: 'Emission Expiry End',
                type: 'input',
                subType: 'date',
                name: 'emission_end',
            },
            {
                label: 'State',
                type: 'select',
                name: 'state',
                values: [
                    { text: 'All', value: '' },
                    { text: 'OR', value: 'OR' },
                    { text: 'NY', value: 'NY' },
                    { text: 'KYU', value: 'KYU' },
                    { text: 'NM', value: 'NM' },
                    { text: 'CT', value: 'CT' },
                    { text: 'US Decal', value: 'US' },
                    { text: 'CARB', value: 'CRB' },
                ]
            },
            {
                label: 'State Type',
                type: 'select',
                name: 'state_type',
                values: [
                    { text: 'Select a type', value: '' },
                    { text: 'R', value: 'R' },
                    { text: 'NR', value: 'NR' },
                    { text: 'Active', value: 'Active' },
                    { text: 'Renew', value: 'Renew' },
                ]
            },
            // {
            //     label: 'Search Options',
            //     type: 'select',
            //     name: 'condition',
            //     values: [
            //         { text: 'All', value: '' },
            //         { text: 'New', value: 'new' },
            //         { text: 'Used', value: 'used' },
            //     ]
            // },
        ],
    };

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [actionItem, setActionItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl, sort);

    useEffect(() => {
        setItems(response?.trucks?.data ?? []);
        setMeta(response?.trucks?.meta ?? []);
        
    }, [response])

    useEffect(() => {
        if (listType === null) return

        var newUrl = new URL(process.env.REACT_APP_BASE_API_URL+'/trucks/'+(listType ?? 'active'));

        url.searchParams.delete('page');
        setCurrentPage(0);
        setApiUrl(newUrl)
    }, [listType])

    useEffect(() => {
        for (const [key, value] of searchParams.entries())
        {
            url.searchParams.set(key, value);
        }
        setApiUrl(url);
    }, [searchParams])

    const updateState = (id, type) => {
        var url = '';
        var buttonClass = 'danger';
        if (type == 'archive') {
            url = process.env.REACT_APP_BASE_API_URL+'/truck-archive/'+id
        }
        else if (type == 'restore') {
            url = process.env.REACT_APP_BASE_API_URL+'/truck-restore/'+id
            buttonClass = 'success'
        }

        if (url) 
        {
            setActionItem({id: id, type: type, url: url, buttonClass: buttonClass})
        }
    }

    const handleSort = (column) => {
        setSort(sort => {
            var type = 'asc'
            if (sort?.column == column)
            {
                type = sort?.type == 'asc' ? 'desc' : 'asc'
            }

            return {column: column, type: type}
        })

        setCurrentPage(0);
        var url = new URL(apiUrl);
        url.searchParams.delete('page');
        setApiUrl(url);
    }

    return (
        <div className="container-fluid">
            {actionItem ? <ActionPopup setItems={setItems} actionItem={actionItem} setActionItem={setActionItem} /> : null}
            {deleteItem && user.role == 'Admin' ? <DeletePopup setItems={setItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            {updateStatusItem ? <UpdateStatus setItems={setItems} updateStatusItem={updateStatusItem} setUpdateStatusItem={setUpdateStatusItem} /> : null}
            {updateCommentsItem ? <UpdateComments setItems={setItems} updateCommentsItem={updateCommentsItem} setUpdateCommentsItem={setUpdateCommentsItem} /> : null}
            <Filter setApiUrl={setApiUrl} setCurrentPage={setCurrentPage} filters={filters} />
            
            <Card className="shadow mb-4">
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Truck List</h5>
                    <div className='d-flex'>
                        <Button as={Link} target="_blank" to={'print?type='+(listType ?? 'active')+'&'+searchParams.toString()+'&sort='+sort?.column+'&sort_type='+sort?.type} variant="primary" className="btn-sm mr-2">Print</Button>
                        <Button as={Link} to={'create'} variant="primary" className="btn-sm mr-2">Add New</Button>
                        <Form.Select 
                            className="form-control"
                            value={listType ?? 'active'}
                            onChange={(e) => setListType(e.target.value)}
                            style={{ width: 'auto', fontSize: '14px', height: '30px', padding: '0px 8px' }}
                            >
                            <option value='all'>All Trucks</option>
                            <option value='active'>Active Trucks</option>
                            <option value='archive'>Archived Trucks</option>
                        </Form.Select>
                    </div>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        items?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <>
                                <Table responsive bordered hover striped>
                                    <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th onClick={() => handleSort('legal')} className={`${sort?.column == 'legal' ? 'sort-'+sort?.type : ''}`}>Legal Name</th>
                                            <th onClick={() => handleSort('phone')} className={`${sort?.column == 'phone' ? 'sort-'+sort?.type : ''}`}>Phone</th>
                                            <th onClick={() => handleSort('date')} className={`${sort?.column == 'date' ? 'sort-'+sort?.type : ''}`}>Date</th>
                                            <th onClick={() => handleSort('vin')} className={`${sort?.column == 'vin' ? 'sort-'+sort?.type : ''}`}>Vin#</th>
                                            <th onClick={() => handleSort('fleet')} className={`${sort?.column == 'fleet' ? 'sort-'+sort?.type : ''}`}>Fleet</th>
                                            <th onClick={() => handleSort('fleet_expiry')} className={`${sort?.column == 'fleet_expiry' ? 'sort-'+sort?.type : ''}`}>Fleet Expiry</th>
                                            <th onClick={() => handleSort('unit')} className={`${sort?.column == 'unit' ? 'sort-'+sort?.type : ''}`}>Unit No</th>
                                            <th onClick={() => handleSort('plate')} className={`${sort?.column == 'plate' ? 'sort-'+sort?.type : ''}`}>Plate No</th>
                                            <th onClick={() => handleSort('updated_by')} className={`${sort?.column == 'updated_by' ? 'sort-'+sort?.type : ''}`}>Updated By</th>
                                            <th onClick={() => handleSort('updated_date')} className={`${sort?.column == 'updated_date' ? 'sort-'+sort?.type : ''}`}>Updated Date</th>
                                            <th onClick={() => handleSort('or')} className={`${sort?.column == 'or' ? 'sort-'+sort?.type : ''}`}>OR</th>
                                            <th onClick={() => handleSort('ny')} className={`${sort?.column == 'ny' ? 'sort-'+sort?.type : ''}`}>NY</th>
                                            <th onClick={() => handleSort('kyu')} className={`${sort?.column == 'kyu' ? 'sort-'+sort?.type : ''}`}>KYU</th>
                                            <th onClick={() => handleSort('nm')} className={`${sort?.column == 'nm' ? 'sort-'+sort?.type : ''}`}>NM</th>
                                            <th onClick={() => handleSort('ct')} className={`${sort?.column == 'ct' ? 'sort-'+sort?.type : ''}`}>CT</th>
                                            <th onClick={() => handleSort('us')} className={`${sort?.column == 'us' ? 'sort-'+sort?.type : ''}`}>US Decal</th>
                                            <th onClick={() => handleSort('crb')} className={`${sort?.column == 'crb' ? 'sort-'+sort?.type : ''}`}>CRB</th>
                                            <th style={{width: '1px' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item, index) => (
                                            <tr key={ item.id } style={{
                                                backgroundColor: item.is_duplicate ? '#ffbfbf' : (item.is_duplicate_plate ? '#b4f9b3' : '')
                                            }}>
                                                <td className="bg-dark text-white align-middle text-center">{ (currentPage*75) + index + 1 }</td>
                                                <td>
                                                    <Link to={`/customer-list/edit/${item.customer_id}`}>{ item.legal_name }</Link>
                                                    { !item.is_active ? <br /> : null }
                                                    { !item.is_active ? <span className={`badge badge-table badge-danger mr-1`}>Archived</span> : null }
                                                </td>
                                                <td>{ item.telephone }</td>
                                                <td>{ item.truck_date }</td>
                                                <td><Link to={`edit/${item.id}`}>{ item.vin_no }</Link></td>
                                                <td>{ item.irp_acc }</td>
                                                <td>{ item.fleet_expiry }</td>
                                                <td style={{ backgroundColor: '#01315A', color: '#ffffff' }}>{ item.unit_no }</td>
                                                <td>{ item.plat_no }</td>
                                                <td>{ item.last_updated_by }</td>
                                                <td>{ item.last_updated_ts }</td>
                                                <td className={`font-weight-bold text-${item.or_file_color}`}>{ item.or_file }</td>
                                                <td className={`font-weight-bold text-${item.ny_color}`}>{ item.ny }</td>
                                                <td className={`font-weight-bold text-${item.kyu_color}`}>{ item.kyu }</td>
                                                <td className={`font-weight-bold text-${item.nmwdt_color}`}>{ item.nmwdt }</td>
                                                <td className={`font-weight-bold text-${item.ct_color}`}>{ item.ct }</td>
                                                <td className={`font-weight-bold text-${item.ucr_color}`}>{ item.ucr }</td>
                                                <td className={`font-weight-bold text-${item.crb_color}`}>{ item.crb }</td>
                                                <td style={{padding: '0.3rem'}}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="primary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => setUpdateStatusItem(item)}>Status</Dropdown.Item>
                                                            { item.is_active ? 
                                                                <Dropdown.Item onClick={() => updateState(item.id, 'archive')}>Archive</Dropdown.Item> : 
                                                                <Dropdown.Item onClick={() => updateState(item.id, 'restore')}>Restore</Dropdown.Item>
                                                            }
                                                            <Dropdown.Item onClick={() => setUpdateCommentsItem(item)}>Comments</Dropdown.Item>
                                                            <Dropdown.Item as={Link} to={`edit/${item.id}`}>Edit</Dropdown.Item>
                                                            { user.role == 'Admin' ? <Dropdown.Item onClick={() => setDeleteItem({id: item.id, url: 'trucks/'+item.id})} className="text-danger">Delete</Dropdown.Item> : null }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />

                                <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
            <Footer/>
        </div>
    );
}
 
export default Index;