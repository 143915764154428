import { useEffect, useState, useRef } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { store } from '../../../redux/store';
import { removeUser } from '../../../redux/userSlice';
import useFetch from '../../../useFetch';
import Input from "./../Forms/Input";
import Select from "./../Forms/Select";
import Textarea from "./../Forms/Textarea";
import Footer from '../../Footer';

const stateInfos = [
    {text: 'Not Required', value: 0},
    {text: 'Required', value: 1},
    {text: 'Active', value: 2},
    {text: 'Renew', value: 3},
]

const Edit = () => {
    document.title = "Edit Truck | Smart Trucking Services";
    
    const { id } = useParams();

    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const [duplicateVin, setDuplicateVin] = useState(null);
    const [duplicatePlate, setDuplicatePlate] = useState(null);

    const cardRef = useRef();

    const [selectedCustomerTruck, setSelectedCustomerTruck] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [fleets, setFleets] = useState([{text: 'Select a fleet', value: ''}])

    const [customer, setCustomer] = useState('');
    const [date, setDate] = useState('');
    const [fleet, setFleet] = useState('');
    const [color, setColor] = useState('');
    const [unitNo, setUnitNo] = useState('');
    const [plateNo, setPlateNo] = useState('');
    const [make, setMake] = useState('');
    const [year, setYear] = useState('');
    const [vinNo, setVinNo] = useState('');
    const [emissionDate, setEmissionDate] = useState('');
    const [comments, setComments] = useState('');
    const [orFile, setOrFile] = useState(0)
    const [ny, setNy] = useState(0)
    const [kyu, setKyu] = useState(0)
    const [ct, setCt] = useState(0)
    const [nmwdt, setNmwdt] = useState(0)
    const [ucr, setUcr] = useState(0)
    const [crb, setCrb] = useState(0)

    const { response: truck } = useFetch(process.env.REACT_APP_BASE_API_URL+'/truck-details/'+id);
    useEffect(() => {
        if (truck?.data)
        {
            setCustomer(truck.data.customer_id)
            setDate(truck.data.truck_date);
            setColor(truck.data.color);
            setUnitNo(truck.data.unit_no);
            setPlateNo(truck.data.plat_no);
            setMake(truck.data.make);
            setYear(truck.data.year);
            setVinNo(truck.data.vin_no);
            setEmissionDate(truck.data.emission_test_date);
            setComments(truck.data.comments);
            setOrFile(truck.data.or_file_value)
            setNy(truck.data.ny_value)
            setKyu(truck.data.kyu_value)
            setCt(truck.data.ct_value)
            setNmwdt(truck.data.nmwdt_value)
            setUcr(truck.data.ucr_value)
            setCrb(truck.data.crb_value)
            setFleet(truck.data.irp_acc)
        }
    }, [truck])

    const { response: customers } = useFetch(process.env.REACT_APP_BASE_API_URL+'/customer-dropdown-manage-trucks');

    useEffect(() => {
        if (!customer) {
            setSelectedCustomer(null)
            return
        }

        console.log('selectedCustomer', selectedCustomer, selectedCustomerTruck)
        
        var cust = customers?.customers?.find(value => value.value == customer)
        
        if (selectedCustomer === null)
        {
            setSelectedCustomerTruck(cust)

            setFleets(current => {
                return [
                    {text: 'Select a fleet', value: ''},
                    ...(cust?.irp_acc_1 ? [{text: 'Fleet1', value: 'Fleet1'}] : []),
                    ...(cust?.irp_acc_2 ? [{text: 'Fleet2', value: 'Fleet2'}] : []),
                    ...(cust?.irp_acc_3 ? [{text: 'Fleet3', value: 'Fleet3'}] : []),
                ]
            })
        }
        else
        {
            setSelectedCustomer(cust)
        }
    }, [customer, customers])
    
    useEffect(() => {
        setOrFile(selectedCustomer?.or_file)
        setNy(selectedCustomer?.ny)
        setKyu(selectedCustomer?.kyu)
        setCt(selectedCustomer?.ct)
        setNmwdt(selectedCustomer?.nmwdt)
        setUcr(selectedCustomer?.ucr)
        
        setFleets(current => {
            if (selectedCustomer?.irp_acc_1) setFleet('Fleet1')
            else if (selectedCustomer?.irp_acc_2) setFleet('Fleet2')
            else if (selectedCustomer?.irp_acc_3) setFleet('Fleet3')
            else setFleet('')
            return [
                {text: 'Select a fleet', value: ''},
                ...(selectedCustomer?.irp_acc_1 ? [{text: 'Fleet1', value: 'Fleet1'}] : []),
                ...(selectedCustomer?.irp_acc_2 ? [{text: 'Fleet2', value: 'Fleet2'}] : []),
                ...(selectedCustomer?.irp_acc_3 ? [{text: 'Fleet3', value: 'Fleet3'}] : []),
            ]
        })
    }, [selectedCustomer])

    const checkDuplicateVin = () => {
        setIsLoading(true);
        console.log('check vin')
        let formData = new FormData();
        formData.append("vin", vinNo)
        formData.append("plate", plateNo)
        formData.append("truck_id", id)

        fetch(process.env.REACT_APP_BASE_API_URL+'/truck-check-duplicate-vin', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while checking for duplicate vin no, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);
            console.log('data', data)
            if (data?.status == 'ask')
            {
                setDuplicateVin(data.data)
            }
            else if (data?.status == 'allow')
            {
                checkDuplicatePlate();
            }
            else
            {
                setError('There was some error while checking for duplicate vin no, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    const checkDuplicatePlate = () => {
        setIsLoading(true);
        console.log('check plate')
        let formData = new FormData();
        formData.append("customer", customer)
        formData.append("plate", plateNo)
        formData.append("truck_id", id)

        fetch(process.env.REACT_APP_BASE_API_URL+'/truck-check-duplicate-plate', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while checking for duplicate plate no, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);
            console.log('data', data)
            if (data?.status == 'ask')
            {
                setDuplicatePlate(data.data)
            }
            else if (data?.status == 'allow')
            {
                submitForm()
            }
            else
            {
                setError('There was some error while checking for duplicate plate no, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    const submitForm = () => {
        setIsLoading(true);

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("customer", customer)
        formData.append("truck_date", date)
        formData.append("irp_acc", fleet)
        formData.append("color", color)
        formData.append("unit_no", unitNo)
        formData.append("plat_no", plateNo)
        formData.append("make", make)
        formData.append("year", year)
        formData.append("vin_no", vinNo)
        formData.append("emission_test_date", emissionDate)
        formData.append("or_file", orFile)
        formData.append("ny", ny)
        formData.append("kyu", kyu)
        formData.append("ct", ct)
        formData.append("nmwdt", nmwdt)
        formData.append("ucr", ucr)
        formData.append("crb", crb)
        formData.append("comments", comments)
        
        var ORRenewalStatus = 0;
        var NYRenewalStatus = 0;
        var KYURenewalStatus = 0;
        var CTRenewalStatus = 0;
        var NMWDTRenewalStatus = 0;
        var UCRRenewalStatus = 0;
        var CRBRenewalStatus = 0;
        if (orFile == 3) ORRenewalStatus = 1;
        if (ny == 3) NYRenewalStatus = 1;
        if (kyu == 3) KYURenewalStatus = 1;
        if (ct == 3) CTRenewalStatus = 1;
        if (nmwdt == 3) NMWDTRenewalStatus = 1;
        if (ucr == 3) UCRRenewalStatus = 1;
        if (crb == 3) CRBRenewalStatus = 1;

        formData.append("or_renewal_status", ORRenewalStatus)
        formData.append("ny_renewal_status", NYRenewalStatus)
        formData.append("kyu_renewal_status", KYURenewalStatus)
        formData.append("ct_renewal_status", CTRenewalStatus)
        formData.append("nmwdt_renewal_status", NMWDTRenewalStatus)
        formData.append("ucr_renewal_status", UCRRenewalStatus)
        formData.append("crb_renewal_status", CRBRenewalStatus)
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/trucks/'+id, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while editing truck, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while editing truck, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);
            setError(error.message);
        })
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (fleets.length > 1 && !fleet)
        {
            window.alert('Select a value for fleet.')
            return
        }
        if (year.length > 0 && year.length != 4)
        {
            window.alert('Enter a valid year.')
            return
        }
        if (year.length == 4 && year > new Date().getFullYear() + 1)
        {
            window.alert('Year should not be more than current year.')
            return
        }
        if (vinNo.length != 17)
        {
            window.alert('Vin no should be a 17 alphanumeric no.')
            return
        }

        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        checkDuplicateVin()
    }
    
    const handleDuplicateVin = () => {
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        fetch(process.env.REACT_APP_BASE_API_URL+'/truck-duplicate/'+duplicateVin?.truck_id+'?truck_id='+id, {
            method: 'GET',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while duplicating vin no, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);
            if (data?.status == 'success')
            {                
                setDuplicateVin(null)

                checkDuplicatePlate();
            }
            else
            {
                setError('There was some error while duplicating vin no, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message ?? 'There was some error while duplicating vin no, please try again.');
        })
    }
    
    const handleDuplicatePlate = () => {
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        fetch(process.env.REACT_APP_BASE_API_URL+'/plate-duplicate/'+duplicatePlate?.truck_id+'?truck_id='+id, {
            method: 'GET',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while duplicating plate no, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);
            if (data?.status == 'success')
            {
                setDuplicatePlate(null)

                submitForm()
            }
            else
            {
                setError('There was some error while duplicating plate no, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message ?? 'There was some error while duplicating plate no, please try again.');
        })
    }

    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <Card ref={cardRef} className="shadow mb-4">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Edit Truck</h5>
                        <Button onClick={() => navigate(-1)} variant="primary" className="btn-sm">Back</Button>
                    </Card.Header>
                    <Card.Body>
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <fieldset className='mb-3'>
                            <legend>Client Info</legend>
                            <div className="form-row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Form.Label className="mb-1">Client Name *</Form.Label>
                                    <Form.Select 
                                        className="form-control"
                                        value={customer}
                                        onChange={ (e) => {
                                            setCustomer(e.target.value)
                                            setSelectedCustomer(customers?.customers?.find(value => value.value == e.target.value))
                                        }}
                                        required={true}
                                        >
                                        { [{text: customers?.customers ? 'Select a customer' : 'Loading...', value: ''}, ...(customers?.customers ?? [])].map((value) => (
                                            <option key={value.value} value={ value.value }>{ value.text }</option>
                                        )) }
                                    </Form.Select>
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Trade Name"
                                        value={selectedCustomer?.trade_name ?? selectedCustomerTruck?.trade_name}
                                        disabled={true}
                                        />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className='mb-3'>
                            <legend>Truck Info</legend>
                            <div className="form-row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Date *"
                                        type={'date'}
                                        value={date}
                                        setter={setDate}
                                        required={true}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Select 
                                        label="Fleet"
                                        value={fleet}
                                        setter={setFleet}
                                        options={fleets}
                                        required={fleets.length > 1}
                                        />
                                    {fleets.length > 1 && !fleet ? <p className='mb-0 small text-danger'>Select a value for fleet.</p> : null}
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Truck Color"
                                        value={color}
                                        setter={setColor}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Unit No"
                                        value={unitNo}
                                        setter={setUnitNo}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Plate No *"
                                        value={plateNo}
                                        setter={setPlateNo}
                                        required={true}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Make"
                                        value={make}
                                        setter={setMake}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Year"
                                        value={year}
                                        setter={setYear}
                                        />
                                    {year.length > 0 && year.length != 4 ? <p className='mb-0 small text-danger'>Enter a valid year.</p> : null}
                                    {year.length == 4 && year > new Date().getFullYear() + 1 ? <p className='mb-0 small text-danger'>Year should not be more than current year.</p> : null}
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Vin No *"
                                        value={vinNo}
                                        setter={setVinNo}
                                        required={true}
                                        />
                                    {vinNo.length > 0 && vinNo.length != 17 ? <p className='mb-0 small text-danger'>Vin no should be a 17 alphanumeric no.</p> : null}
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Emission Test Date"
                                        type={'date'}
                                        value={emissionDate}
                                        setter={setEmissionDate}
                                        />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <Form.Label className="mb-1">Comments</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={comments}
                                        onChange={ (e) => setComments(e.target.value) }
                                        rows={3}
                                    ></Form.Control>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className='mb-3'>
                            <legend>States Required Info</legend>
                            <div className="form-row row-cols-xl-3">
                                <div className="col mb-3">
                                    <Select 
                                        label="OR"
                                        value={orFile}
                                        setter={setOrFile}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col mb-3">
                                    <Select 
                                        label="NY"
                                        value={ny}
                                        setter={setNy}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col mb-3">
                                    <Select 
                                        label="KY"
                                        value={kyu}
                                        setter={setKyu}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col mb-3">
                                    <Select 
                                        label="NM"
                                        value={nmwdt}
                                        setter={setNmwdt}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col mb-3">
                                    <Select 
                                        label="CT"
                                        value={ct}
                                        setter={setCt}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col mb-3">
                                    <Select 
                                        label="US Decal"
                                        value={ucr}
                                        setter={setUcr}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col mb-3">
                                    <Select 
                                        label="CARB"
                                        value={crb}
                                        setter={setCrb}
                                        options={stateInfos}
                                        />
                                </div>
                            </div>
                        </fieldset>
                    </Card.Body>
                    <Card.Footer className='text-right'>
                        <button 
                            type="submit" 
                            className="btn btn-primary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Loading...' : 'Submit' }</button>
                    </Card.Footer>
                </Card>
            </form>

            <Modal show={duplicateVin ? true : false} onHide={() => setDuplicateVin(null)} className="status-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Truck Already Exists</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='mb-0'>Vin No is already exists with client (<b>{duplicateVin?.legal_name}</b>). Are you sure you want to duplicate this truck?</p>
                </Modal.Body>
                <Modal.Footer className={'justify-content-center'}>
                    <Button variant="light" size="sm" onClick={() => setDuplicateVin(null)} style={{ minWidth: '80px' }}>
                        No
                    </Button>
                    <Button variant="primary" size="sm" onClick={handleDuplicateVin} disabled={isLoading} style={{ minWidth: '80px' }}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={duplicatePlate ? true : false} onHide={() => setDuplicatePlate(null)} className="status-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Plate Number Already Exists</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='mb-0'>Plate No already exists for Vin No (<b>{duplicatePlate?.vin_no}</b>). Are you sure you want to duplicate this plate?</p>
                </Modal.Body>
                <Modal.Footer className={'justify-content-center'}>
                    <Button variant="light" size="sm" onClick={() => setDuplicatePlate(null)} style={{ minWidth: '80px' }}>
                        No
                    </Button>
                    <Button variant="primary" size="sm" onClick={handleDuplicatePlate} disabled={isLoading} style={{ minWidth: '80px' }}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Footer/>
        </div>
    );
}
 
export default Edit;