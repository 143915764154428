import { useEffect, useState, useRef } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { store } from '../../../redux/store';
import { removeUser } from '../../../redux/userSlice';
import useFetch from '../../../useFetch';
import Input from "./../Forms/Input";
import Select from "./../Forms/Select";
import Textarea from "./../Forms/Textarea";

const stateInfos = [
    {text: 'Not Required', value: 0},
    {text: 'Pending', value: 1},
    {text: 'Done', value: 2},
]

const Create = () => {
    document.title = "Add Customer IFTA | Smart Trucking Services";
    
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    
    const [customer, setCustomer] = useState('');
    const [invoiceNo, setInvoiceNo] = useState('');
    const [quarter, setQuarter] = useState('');
    const [year, setYear] = useState('');
    const [or, setOr] = useState(0)
    const [orAmount, setOrAmount] = useState('')
    const [ny, setNy] = useState(0)
    const [nyAmount, setNyAmount] = useState('')
    const [kyu, setKyu] = useState(0)
    const [kyuAmount, setKyuAmount] = useState('')
    const [ct, setCt] = useState(0)
    const [ctAmount, setCtAmount] = useState('')
    const [nmwdt, setNmwdt] = useState(0)
    const [nmwdtAmount, setNmwdtAmount] = useState('')
    const [ucr, setUcr] = useState(0)
    const [ucrAmount, setUcrAmount] = useState('')

    const { response: customers } = useFetch(process.env.REACT_APP_BASE_API_URL+'/customer-dropdown-manage-trucks');

    useEffect(() => {
        if (!customer) {
            setSelectedCustomer(null)
            return
        }
        
        setSelectedCustomer(customers?.customers?.find(value => value.value == customer))
    }, [customer])
    
    useEffect(() => {
        setOr(selectedCustomer?.or_file)
        setNy(selectedCustomer?.ny)
        setKyu(selectedCustomer?.kyu)
        setCt(selectedCustomer?.ct)
        setNmwdt(selectedCustomer?.nmwdt)
        setUcr(selectedCustomer?.ucr)
    }, [selectedCustomer])
    
    const handleSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("customer", customer)
        formData.append("invoice_no", invoiceNo)
        formData.append("quarter", quarter)
        formData.append("year", year)
        formData.append("or", or)
        formData.append("or_amount", orAmount)
        formData.append("ny", ny)
        formData.append("ny_amount", nyAmount)
        formData.append("kyu", kyu)
        formData.append("kyu_amount", kyuAmount)
        formData.append("ct", ct)
        formData.append("ct_amount", ctAmount)
        formData.append("nmwdt", nmwdt)
        formData.append("nmwdt_amount", nmwdtAmount)
        formData.append("ucr", ucr)
        formData.append("ucr_amount", ucrAmount)

        fetch(process.env.REACT_APP_BASE_API_URL+'/customer-iftas', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while adding ifta, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);

                setCustomer('');
                setInvoiceNo('');
                setQuarter('');
                setYear('');
                setOr(0)
                setOrAmount('')
                setNy(0)
                setNyAmount('')
                setKyu(0)
                setKyuAmount('')
                setCt(0)
                setCtAmount('')
                setNmwdt(0)
                setNmwdtAmount('')
                setUcr(0)
                setUcrAmount('')
                setSelectedCustomer(null);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while adding ifta, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);
            setError(error.message);
        })
    }

    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <Card ref={cardRef} className="shadow mb-4">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Add Customer IFTA</h5>
                        <Button onClick={() => navigate(-1)} variant="primary" className="btn-sm">Back</Button>
                    </Card.Header>
                    <Card.Body>
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <fieldset className='mb-3'>
                            <legend>Client Info</legend>
                            <div className="form-row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Select 
                                        label="Client Name *"
                                        value={customer}
                                        setter={setCustomer}
                                        options={[{text: customers?.customers ? 'Select a customer' : 'Loading...', value: ''}, ...(customers?.customers ?? [])]}
                                        required={true}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Trade Name"
                                        value={selectedCustomer ? selectedCustomer?.trade_name : ''}
                                        disabled={true}
                                        />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className='mb-3'>
                            <legend>IFTA Detail</legend>
                            <div className="form-row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Invoice No"
                                        value={invoiceNo}
                                        setter={setInvoiceNo}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Select 
                                        label="Quarter *"
                                        value={quarter}
                                        setter={setQuarter}
                                        options={[
                                            { value: '', text: 'Select a Quarter' },
                                            { value: 'First', text: '1ST QUARTER' },
                                            { value: 'Second', text: '2ND QUARTER' },
                                            { value: 'Third', text: '3RD QUARTER' },
                                            { value: 'Fourth', text: '4TH QUARTER' },
                                        ]}
                                        required={true}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Select 
                                        label="Year *"
                                        value={year}
                                        setter={setYear}
                                        options={[
                                            { value: '', text: 'Select a Year' },
                                            { value: '2010', text: '2010' },
                                            { value: '2011', text: '2011' },
                                            { value: '2012', text: '2012' },
                                            { value: '2013', text: '2013' },
                                            { value: '2014', text: '2014' },
                                            { value: '2015', text: '2015' },
                                        ]}
                                        required={true}
                                        />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className='mb-3'>
                            <legend>States Required Info</legend>
                            <div className="form-row row-cols-xl-4">
                                <div className="col mb-3">
                                    <Select 
                                        label="OR"
                                        value={or}
                                        setter={setOr}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="OR Amount"
                                        value={orAmount}
                                        setter={setOrAmount}
                                        />
                                </div>
                                <div className="col mb-3">
                                    <Select 
                                        label="NY"
                                        value={ny}
                                        setter={setNy}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="NY Amount"
                                        value={nyAmount}
                                        setter={setNyAmount}
                                        />
                                </div>
                                <div className="col mb-3">
                                    <Select 
                                        label="KY"
                                        value={kyu}
                                        setter={setKyu}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="KYU Amount"
                                        value={kyuAmount}
                                        setter={setKyuAmount}
                                        />
                                </div>
                                <div className="col mb-3">
                                    <Select 
                                        label="NM"
                                        value={nmwdt}
                                        setter={setNmwdt}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="NM Amount"
                                        value={nmwdtAmount}
                                        setter={setNmwdtAmount}
                                        />
                                </div>
                                <div className="col mb-3">
                                    <Select 
                                        label="CT"
                                        value={ct}
                                        setter={setCt}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="CT Amount"
                                        value={ctAmount}
                                        setter={setCtAmount}
                                        />
                                </div>
                                <div className="col mb-3">
                                    <Select 
                                        label="US Decal"
                                        value={ucr}
                                        setter={setUcr}
                                        options={stateInfos}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="US Decal Amount"
                                        value={ucrAmount}
                                        setter={setUcrAmount}
                                        />
                                </div>
                            </div>
                        </fieldset>
                    </Card.Body>
                    <Card.Footer className='text-right'>
                        <button 
                            type="submit" 
                            className="btn btn-primary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Loading...' : 'Submit' }</button>
                    </Card.Footer>
                </Card>
            </form>
        </div>
    );
}
 
export default Create;