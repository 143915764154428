import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Filter from '../Filters/Filter';
import useFetch from '../../../useFetch';
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import Paginate from '../../../Paginate';
import { store } from '../../../redux/store';
import { removeUser } from '../../../redux/userSlice';

const Index = () => {
    document.title = "Update CVOR | Smart Trucking Services";
    
    let navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();

    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/cvor-customers-updates');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [sort, setSort] = useState({column: 'legal', type: 'asc'})
    const [apiUrl, setApiUrl] = useState(url);

    const filters = {
        action: apiUrl,
        method: 'GET',
        fields: [
            {
                label: 'Expiry Type',
                type: 'select',
                name: 'type',
                values: [
                    { text: 'CVOR Expiring Soon', value: 'expiring' },
                    { text: 'CVOR Already Expired', value: 'expired' },
                    { text: 'CVOR Expiry by Date Range', value: 'custom' },
                ]
            },
            {
                label: 'CVOR Expiry Start',
                type: 'input',
                subType: 'date',
                name: 'start',
            },
            {
                label: 'CVOR Expiry End',
                type: 'input',
                subType: 'date',
                name: 'end',
            },
            // {
            //     label: 'Search Options',
            //     type: 'select',
            //     name: 'condition',
            //     values: [
            //         { text: 'All', value: '' },
            //         { text: 'New', value: 'new' },
            //         { text: 'Used', value: 'used' },
            //     ]
            // },
        ],
    };

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const { response, isLoading: fetchIsLoading, error: fetchError } = useFetch(apiUrl, sort);

    useEffect(() => {
        setItems(response?.data ?? []);
        setMeta(response?.meta ?? []);
        setCheckedItems([])
    }, [response])

    useEffect(() => {
        for (const [key, value] of searchParams.entries())
        {
            url.searchParams.set(key, value);
        }
        setApiUrl(url);
    }, [searchParams])
    
    const handleSubmit = () => {
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        fetch(apiUrl, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token"),
            },
            body: JSON.stringify({'ids': checkedItems}),
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while updating, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);
                setItems(data?.data?.data ?? []);
                setMeta(data?.data?.meta ?? []);
                setCheckedItems([])
                setSort({column: 'legal', type: 'asc'})

                //setLegalName('');
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while updating, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    const toggleCheck = (id) => {
        setCheckedItems(current => {
            if (current.includes(id)) return current.filter(value => value != id)
            else return [...current, id]
        })
    }

    const handleSort = (column) => {
        setError(false);
        setSuccess(false);
        
        setSort(sort => {
            var type = 'asc'
            if (sort?.column == column)
            {
                type = sort?.type == 'asc' ? 'desc' : 'asc'
            }

            return {column: column, type: type}
        })

        setCurrentPage(0);
        var url = new URL(apiUrl);
        url.searchParams.delete('page');
        setApiUrl(url);
    }

    return (
        <div className="container-fluid">
            <Filter setApiUrl={setApiUrl} setCurrentPage={setCurrentPage} filters={filters} />
            
            <Card className="shadow mb-4">
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Update CVOR</h5>
                    <div className='d-flex'>
                        <Button onClick={handleSubmit} variant="primary" disabled={checkedItems.length == 0 || isLoading} className={`btn-sm mr-2 ${checkedItems.length == 0 || isLoading ? 'disabled' : ''}`}>{isLoading ? 'Updating...' : 'Update Selected'}</Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { fetchIsLoading && <h5 className="text-center">Loading Data</h5> }
                    
                    { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                    { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                    { validationErrors.length > 0 &&
                        <div className="alert-msg-danger">
                            <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                            <ul className="mb-0" style={{fontSize: '15px'}}>
                                { validationErrors.map((validationError, index) => (
                                    <li key={index}>{ validationError }</li>
                                ))}
                            </ul>
                        </div>
                    }

                    { !fetchError && !fetchIsLoading ? (
                        items?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <>
                                <div className='d-flex flex-wrap mb-2'>
                                    <Button onClick={() => setCheckedItems(items.map(value => value.id))} variant="success" className="btn-sm mr-2">Check All</Button>                                    
                                    <Button onClick={() => setCheckedItems([])} variant="warning" className="btn-sm mr-2">Uncheck All</Button>                                    
                                </div>

                                <Table responsive bordered hover striped>
                                    <thead>
                                        <tr>
                                            <th className="text-center"></th>
                                            <th onClick={() => handleSort('legal')} className={`${sort?.column == 'legal' ? 'sort-'+sort?.type : ''}`}>Legal Name</th>
                                            <th onClick={() => handleSort('trade')} className={`${sort?.column == 'trade' ? 'sort-'+sort?.type : ''}`}>Trade Name</th>
                                            <th onClick={() => handleSort('customer')} className={`${sort?.column == 'customer' ? 'sort-'+sort?.type : ''}`}>Customer Name</th>
                                            <th onClick={() => handleSort('telephone')} className={`${sort?.column == 'telephone' ? 'sort-'+sort?.type : ''}`}>Telephone#</th>
                                            <th onClick={() => handleSort('cellphone')} className={`${sort?.column == 'cellphone' ? 'sort-'+sort?.type : ''}`}>Cellphone#</th>
                                            <th onClick={() => handleSort('fax')} className={`${sort?.column == 'fax' ? 'sort-'+sort?.type : ''}`}>Fax#</th>
                                            <th onClick={() => handleSort('email')} className={`${sort?.column == 'email' ? 'sort-'+sort?.type : ''}`}>Email</th>
                                            <th onClick={() => handleSort('cvor')} className={`${sort?.column == 'cvor' ? 'sort-'+sort?.type : ''}`}>CVOR Expiry</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item, index) => (
                                            <tr key={ item.id } onClick={() => toggleCheck(item.id)} style={{
                                                backgroundColor: checkedItems.includes(item.id) ? '#b4f9b3' : ''
                                            }}>
                                                <td className={`${checkedItems.includes(item.id) ? 'bg-success' : ''} text-white align-middle text-center`}>
                                                    <img src={checkedItems.includes(item.id) ? '/table-check.svg' : '/table-uncheck.svg'} style={{width: '20px'}} />
                                                </td>
                                                <td>
                                                    <Link to={`/customer-list/edit/${item.id}`}>{ item.legal_name }</Link>
                                                    { !item.is_active || item.is_restricted ? <br /> : null }
                                                    { !item.is_active ? <span className={`badge badge-table badge-danger mr-1`}>Archived</span> : null }
                                                    { item.is_restricted ? <span className={`badge badge-table badge-warning mr-1`}>Restricted</span> : null }
                                                </td>
                                                <td>{ item.trade_name }</td>
                                                <td>{ item.customer_name }</td>
                                                <td>{ item.telephone }</td>
                                                <td>{ item.cell }</td>
                                                <td>{ item.fax }</td>
                                                <td><a href={`mailto:${item.email}`}>{ item.email }</a></td>
                                                <td>{ item.cvor_expiry_date }</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />

                                <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
        </div>
    );
}
 
export default Index;