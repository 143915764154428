import { useEffect, useState, useRef } from 'react';
import useFetch from './../../useFetch';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Link, useNavigate } from 'react-router-dom';

const Dashboard = () => {
    let navigate = useNavigate();
    useEffect(() => {
        navigate('/customer-list');
    }, []);

    return null
}
 
export default Dashboard;