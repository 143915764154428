import { useEffect, useState,useRef } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Filter from '../Filters/Filter';
import useFetch from '../../../useFetch';
import Paginate from '../../../Paginate';
import DeletePopup from '../DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import ActionPopup from '../ActionPopup';
import {useReactToPrint} from  'react-to-print'
import Footer from '../../Footer';
const HazmatCustomer = () => {
    document.title = "Hazmat Customers | Smart Trucking Services";
    const contetPdf = useRef()
    const generatePdf = useReactToPrint({
        content:()=>contetPdf.current,
  
    })
    let navigate = useNavigate();
    
    const [searchParams, setSearchParams] = useSearchParams();

    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/hazmat-customers');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [sort, setSort] = useState({column: 'legal', type: 'asc'})
    const [apiUrl, setApiUrl] = useState(url);

    const filters = {
        action: apiUrl,
        method: 'GET',
        fields: [
            {
                label: 'Legal Name',
                type: 'input',
                name: 'legal',
            },
            {
                label: 'Trade Name',
                type: 'input',
                name: 'trade',
            },
            {
                label: 'Cell No',
                type: 'input',
                name: 'cell',
            },
            // {
            //     label: 'Search Options',
            //     type: 'select',
            //     name: 'condition',
            //     values: [
            //         { text: 'All', value: '' },
            //         { text: 'New', value: 'new' },
            //         { text: 'Used', value: 'used' },
            //     ]
            // },
        ],
    };

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl, sort);

    useEffect(() => {
        setItems(response?.customers?.data ?? []);
        setMeta(response?.customers?.meta ?? []);
    }, [response])

    useEffect(() => {
        for (const [key, value] of searchParams.entries())
        {
            url.searchParams.set(key, value);
        }
        setApiUrl(url);
    }, [searchParams])

    const handleSort = (column) => {
        setSort(sort => {
            var type = 'asc'
            if (sort?.column == column)
            {
                type = sort?.type == 'asc' ? 'desc' : 'asc'
            }

            return {column: column, type: type}
        })

        setCurrentPage(0);
        var url = new URL(apiUrl);
        url.searchParams.delete('page');
        setApiUrl(url);
    }

    return (
        <div className="container-fluid">
            <Filter setApiUrl={setApiUrl} setCurrentPage={setCurrentPage} filters={filters} />
            
            <Card className="shadow mb-4">
                <Card.Header className="d-flex align-items-center">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Safety Customers</h5>
                    <div className='d-flex'>
                    <Button onClick={generatePdf} variant="primary" className="btn-sm mr-2">Print</Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        items?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <>
                                <Table responsive bordered hover striped ref={contetPdf} >
                                    <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th onClick={() => handleSort('legal')} className={`${sort?.column == 'legal' ? 'sort-'+sort?.type : ''}`}>Legal Name</th>
                                            <th onClick={() => handleSort('trade')} className={`${sort?.column == 'trade' ? 'sort-'+sort?.type : ''}`}>Trade Name</th>
                                            <th onClick={() => handleSort('customer')} className={`${sort?.column == 'customer' ? 'sort-'+sort?.type : ''}`}>Customer Name</th>
                                            <th onClick={() => handleSort('telephone')} className={`${sort?.column == 'telephone' ? 'sort-'+sort?.type : ''}`}>Telephone#</th>
                                            <th onClick={() => handleSort('cellphone')} className={`${sort?.column == 'cellphone' ? 'sort-'+sort?.type : ''}`}>Cellphone#</th>
                                            <th onClick={() => handleSort('fax')} className={`${sort?.column == 'fax' ? 'sort-'+sort?.type : ''}`}>Fax#</th>
                                            <th onClick={() => handleSort('email')} className={`${sort?.column == 'email' ? 'sort-'+sort?.type : ''}`}>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item, index) => (
                                            <tr key={ item.id }>
                                                <td className="bg-dark text-white align-middle text-center">{ (currentPage*75) + index + 1 }</td>
                                                <td>
                                                    <Link to={`/customer-list/edit/${item.id}`}>{ item.legal_name }</Link>
                                                    { !item.is_active || item.is_restricted ? <br /> : null }
                                                    { !item.is_active ? <span className={`badge badge-table badge-danger mr-1`}>Archived</span> : null }
                                                    { item.is_restricted ? <span className={`badge badge-table badge-warning mr-1`}>Restricted</span> : null }
                                                </td>
                                                <td>{ item.trade_name }</td>
                                                <td>{ item.customer_name }</td>
                                                <td>{ item.telephone }</td>
                                                <td>{ item.cell }</td>
                                                <td>{ item.fax }</td>
                                                <td><a href={`mailto:${item.email}`}>{ item.email }</a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />

                                <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
            <Footer/>
        </div>
    );
}
 
export default HazmatCustomer;