import { useEffect, useState, useRef } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { store } from '../../../redux/store';
import { removeUser } from '../../../redux/userSlice';
import useFetch from '../../../useFetch';
import Input from "./../Forms/Input";
import Select from "./../Forms/Select";

const Edit = () => {
    let navigate = useNavigate();
    const { user: userRedux } = useSelector(state => state.user);
    
    useEffect(() => {
        if (userRedux.role !== 'Admin') {
            navigate('/unauthorized');
        }
    }, [userRedux]);
    
    document.title = "Edit User | Smart Trucking Services";
    
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();
    
    const [type, setType] = useState('');
    //const [customer, setCustomer] = useState('');
    const [userId, setUserId] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');

    //const { response: customers } = useFetch(process.env.REACT_APP_BASE_API_URL+'/customer-dropdown');
    
    const { response: user } = useFetch(process.env.REACT_APP_BASE_API_URL+'/user-details/'+id);
    useEffect(() => {
        if (user?.data)
        {
            setType(user.data.role_id)
            //setCustomer(user.data.customer_id)
            setUserId(user.data.code)
            setName(user.data.name)
            setPhone(user.data.phone)
            setAddress(user.data.address)
            setCity(user.data.city)
        }
    }, [user])
    
    const handleSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("name", name)
        formData.append("code", userId)
        formData.append("type", type)
        formData.append("phone", phone)
        formData.append("address", address)
        formData.append("city", city)
        //formData.append("customer", customer)
        formData.append("customer", '')

        fetch(process.env.REACT_APP_BASE_API_URL+'/users/'+id, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while editing user, please try again.');
            }

            return response.json();
        })
        .then(data => {
            //cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while editing user, please try again.');
            }
        })
        .catch(error => {
            //cardRef.current.scrollIntoView();
            setIsLoading(false);
            setError(error.message);
        })
    }

    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <Card ref={cardRef} className="shadow mb-4">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Edit User</h5>
                        <Button onClick={() => navigate(-1)} variant="primary" className="btn-sm">Back</Button>
                    </Card.Header>
                    <Card.Body>
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <div className="form-row">
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Select 
                                    label="User Type *"
                                    value={type}
                                    setter={setType}
                                    options={[
                                        { text: 'Select', value: '' },
                                        { text: 'Admin', value: '1' },
                                        { text: 'User', value: '2' },
                                    ]}
                                    required={true}
                                    />
                            </div>
                            {/* { type == '2' ? (
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Select 
                                        label="Customer"
                                        value={customer}
                                        setter={setCustomer}
                                        options={[{text: customers?.customers ? 'Select a customer' : 'Loading...', value: ''}, ...(customers?.customers ?? [])]}
                                        />
                                </div>
                            ) : null } */}
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="User ID *"
                                    value={userId}
                                    setter={setUserId}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Full Name *"
                                    value={name}
                                    setter={setName}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Phone"
                                    value={phone}
                                    setter={setPhone}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Address"
                                    value={address}
                                    setter={setAddress}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="City"
                                    value={city}
                                    setter={setCity}
                                    />
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer className='text-right'>
                        <button 
                            type="submit" 
                            className="btn btn-primary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Loading...' : 'Submit' }</button>
                    </Card.Footer>
                </Card>
            </form>
        </div>
    );
}
 
export default Edit;