import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Filter from '../Filters/Filter';
import useFetch from '../../../useFetch';
import Paginate from '../../../Paginate';
import DeletePopup from '../DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import ActionPopup from '../ActionPopup';
import { useSelector } from 'react-redux';

const Index = () => {
    document.title = "Customer List | Smart Trucking Services";
    
    let navigate = useNavigate();
    const { user } = useSelector(state => state.user);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [listType, setListType] = useState(null);
    const [columns, setColumns] = useState({
        cc1: false,
        cvor: false,
        ifta: false,
        kyu: false,
        ct: false,
        mc: false,
        nm: false,
        ny: false,
        or: false,
        scac: false,
        scacp: false,
        ucr: false,
        usdot: false,
    })

    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/customers/'+(listType ?? 'active')); 
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [sort, setSort] = useState({column: 'legal', type: 'asc'})
    const [apiUrl, setApiUrl] = useState(url);

    const filters = {
        action: apiUrl,
        method: 'GET',
        fields: [
            {
                label: 'Legal Name',
                type: 'input',
                name: 'legal',
            },
            {
                label: 'Trade Name',
                type: 'input',
                name: 'trade',
            },
            {
                label: 'Cell No',
                type: 'input',
                name: 'cell',
            },
            // {
            //     label: 'Search Options',
            //     type: 'select',
            //     name: 'condition',
            //     values: [
            //         { text: 'All', value: '' },
            //         { text: 'New', value: 'new' },
            //         { text: 'Used', value: 'used' },
            //     ]
            // },
        ],
    };

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [actionItem, setActionItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl, sort);

    useEffect(() => {
        setItems(response?.customers?.data ?? []);
        //console.log(response?.customers?.data)
        setMeta(response?.customers?.meta ?? []);
    }, [response])

    useEffect(() => {
        if (listType === null) return

        var newUrl = new URL(process.env.REACT_APP_BASE_API_URL+'/customers/'+(listType ?? 'active'));

        url.searchParams.delete('page');
        setCurrentPage(0);
        setApiUrl(newUrl)
    }, [listType])

    useEffect(() => {
        for (const [key, value] of searchParams.entries())
        {
            url.searchParams.set(key, value);
        }
        setApiUrl(url);
    }, [searchParams])

    const updateState = (id, type) => {
        var url = '';
        var buttonClass = 'danger';
        if (type == 'archive') {
            url = process.env.REACT_APP_BASE_API_URL+'/customer-archive/'+id
           
        }
        else if (type == 'restore') {
            url = process.env.REACT_APP_BASE_API_URL+'/customer-restore/'+id
            buttonClass = 'success'
        }
        else if (type == 'allow') {
            url = process.env.REACT_APP_BASE_API_URL+'/customer-allow/'+id
            buttonClass = 'success'
        }
        else if (type == 'restrict') {
            url = process.env.REACT_APP_BASE_API_URL+'/customer-restrict/'+id
        }

        if (url) 
        {
            setActionItem({id: id, type: type, url: url, buttonClass: buttonClass})
        }
    }

    const handleSort = (column) => {
        setSort(sort => {
            var type = 'asc'
            if (sort?.column == column)
            {
                type = sort?.type == 'asc' ? 'desc' : 'asc'
            }

            return {column: column, type: type}
        })

        setCurrentPage(0);
        var url = new URL(apiUrl);
        url.searchParams.delete('page');
        setApiUrl(url);
    }

    return (
        <div className="container-fluid">
            {actionItem ? <ActionPopup setItems={setItems} actionItem={actionItem} setActionItem={setActionItem} /> : null}
            {deleteItem && user.role == 'Admin' ? <DeletePopup setItems={setItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            <Filter setApiUrl={setApiUrl} setCurrentPage={setCurrentPage} filters={filters} />
            
            <Card className="shadow mb-4">
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Customer List</h5>
                    <div className='d-flex'>
                        <Button as={Link} target="_blank" to={'print?type='+(listType ?? 'active')+'&'+searchParams.toString()+'&'+(Object.entries(columns).filter(value => value[1] == true).map(value => value[0]+'=1').join('&'))+'&sort='+sort?.column+'&sort_type='+sort?.type} variant="primary" className="btn-sm mr-2">Print</Button>
                        <Button as={Link} to={'create'} variant="primary" className="btn-sm mr-2">Add New</Button>
                        <Form.Select 
                            className="form-control"
                            value={listType ?? 'active'}
                            onChange={(e) => setListType(e.target.value)}
                            style={{ width: 'auto', fontSize: '14px', height: '30px', padding: '0px 8px' }}
                            >
                            <option value='all'>All Customers</option>
                            <option value='active'>Active Customers</option>
                            <option value='archive'>Archived Customers</option>
                        </Form.Select>
                    </div>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        items?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <>
                                <div className='d-flex flex-wrap'>
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="Carrier Code" id={`input-column-cc1`} checked={columns.cc1} onChange={() => setColumns(current => ({...current, cc1: !current.cc1}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="CVOR No" id={`input-column-cvor`} checked={columns.cvor} onChange={() => setColumns(current => ({...current, cvor: !current.cvor}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="IFTA No" id={`input-column-ifta`} checked={columns.ifta} onChange={() => setColumns(current => ({...current, ifta: !current.ifta}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="KY No" id={`input-column-kyu`} checked={columns.kyu} onChange={() => setColumns(current => ({...current, kyu: !current.kyu}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="MC No" id={`input-column-mc`} checked={columns.mc} onChange={() => setColumns(current => ({...current, mc: !current.mc}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="NM No" id={`input-column-nm`} checked={columns.nm} onChange={() => setColumns(current => ({...current, nm: !current.nm}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="NY No" id={`input-column-ny`} checked={columns.ny} onChange={() => setColumns(current => ({...current, ny: !current.ny}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="OR No" id={`input-column-or`} checked={columns.or} onChange={() => setColumns(current => ({...current, or: !current.or}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="CT No" id={`input-column-ct`} checked={columns.ct} onChange={() => setColumns(current => ({...current, ct: !current.ct}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="SCAC No" id={`input-column-scac`} checked={columns.scac} onChange={() => setColumns(current => ({...current, scac: !current.scac}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="SCAC Pwd" id={`input-column-scacp`} checked={columns.scacp} onChange={() => setColumns(current => ({...current, scacp: !current.scacp}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="UCR No" id={`input-column-ucr`} checked={columns.ucr} onChange={() => setColumns(current => ({...current, ucr: !current.ucr}))} />
                                    <FormCheck inline type={'checkbox'} style={{ fontSize: '14px', lineHeight: '1.2' }} className='d-flex align-items-center mr-3 mb-2'
                                        label="US Dot No" id={`input-column-usdot`} checked={columns.usdot} onChange={() => setColumns(current => ({...current, usdot: !current.usdot}))} />
                                </div>

                                <Table responsive bordered hover striped>
                                    <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th onClick={() => handleSort('legal')} className={`${sort?.column == 'legal' ? 'sort-'+sort?.type : ''}`}>Legal Name</th>
                                            <th onClick={() => handleSort('trade')} className={`${sort?.column == 'trade' ? 'sort-'+sort?.type : ''}`}>Trade Name</th>
                                            <th onClick={() => handleSort('customer')} className={`${sort?.column == 'customer' ? 'sort-'+sort?.type : ''}`}>Customer Name</th>
                                            <th onClick={() => handleSort('telephone')} className={`${sort?.column == 'telephone' ? 'sort-'+sort?.type : ''}`}>Telephone#</th>
                                            <th onClick={() => handleSort('cellphone')} className={`${sort?.column == 'cellphone' ? 'sort-'+sort?.type : ''}`}>Cellphone#</th>
                                            <th onClick={() => handleSort('fax')} className={`${sort?.column == 'fax' ? 'sort-'+sort?.type : ''}`}>Fax#</th>
                                            <th onClick={() => handleSort('email')} className={`${sort?.column == 'email' ? 'sort-'+sort?.type : ''}`}>Email</th>
                                            { columns.cc1 ? <th onClick={() => handleSort('carrier')} className={`${sort?.column == 'carrier' ? 'sort-'+sort?.type : ''}`}>Carrier Code</th> : null }
                                            { columns.cvor ? <th onClick={() => handleSort('cvor')} className={`${sort?.column == 'cvor' ? 'sort-'+sort?.type : ''}`}>CVOR No</th> : null }
                                            { columns.ifta ? <th onClick={() => handleSort('ifta')} className={`${sort?.column == 'ifta' ? 'sort-'+sort?.type : ''}`}>IFTA No</th> : null }
                                            { columns.kyu ? <th onClick={() => handleSort('ky')} className={`${sort?.column == 'ky' ? 'sort-'+sort?.type : ''}`}>KY No</th> : null }
                                            { columns.mc ? <th onClick={() => handleSort('mc')} className={`${sort?.column == 'mc' ? 'sort-'+sort?.type : ''}`}>MC No</th> : null }
                                            { columns.nm ? <th onClick={() => handleSort('nm')} className={`${sort?.column == 'nm' ? 'sort-'+sort?.type : ''}`}>NM No</th> : null }
                                            { columns.ny ? <th onClick={() => handleSort('ny')} className={`${sort?.column == 'ny' ? 'sort-'+sort?.type : ''}`}>NY No</th> : null }
                                            { columns.or ? <th onClick={() => handleSort('or')} className={`${sort?.column == 'or' ? 'sort-'+sort?.type : ''}`}>OR No</th> : null }
                                            { columns.ct ? <th onClick={() => handleSort('ct')} className={`${sort?.column == 'ct' ? 'sort-'+sort?.type : ''}`}>CT No</th> : null }
                                            { columns.scac ? <th onClick={() => handleSort('scac_no')} className={`${sort?.column == 'scac_no' ? 'sort-'+sort?.type : ''}`}>SCAC No</th> : null }
                                            { columns.scacp ? <th onClick={() => handleSort('scac_pass')} className={`${sort?.column == 'scac_pass' ? 'sort-'+sort?.type : ''}`}>SCAC Pwd</th> : null }
                                            { columns.ucr ? <th onClick={() => handleSort('ucr')} className={`${sort?.column == 'ucr' ? 'sort-'+sort?.type : ''}`}>UCR No</th> : null }
                                            { columns.usdot ? <th onClick={() => handleSort('us')} className={`${sort?.column == 'us' ? 'sort-'+sort?.type : ''}`}>US Dot No</th> : null }
                                            <th style={{width: '1px' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item, index) => (
                                            <tr key={ item.id }>
                                                <td className="bg-dark text-white align-middle text-center">{ (currentPage*75) + index + 1 }</td>
                                                <td>
                                                    <Link to={`edit/${item.id}`}>{ item.legal_name }</Link>
                                                    { !item.is_active || item.is_restricted ? <br /> : null }
                                                    { !item.is_active ? <span className={`badge badge-table badge-danger mr-1`}>Archived</span> : null }
                                                    { item.is_restricted ? <span className={`badge badge-table badge-warning mr-1`}>Restricted</span> : null }
                                                </td>
                                                <td>{ item.trade_name }</td>
                                                <td>{ item.customer_name }</td>
                                                <td>{ item.telephone }</td>
                                                <td>{ item.cell }</td>
                                                <td>{ item.fax }</td>
                                                <td><a href={`mailto:${item.email}`}>{ item.email }</a></td>
                                                { columns.cc1 ? <td>{item.carrier_code_1}</td> : null }
                                                { columns.cvor ? <td>{item.cvor}</td> : null }
                                                { columns.ifta ? <td>{item.ifta_acc}</td> : null }
                                                { columns.kyu ? <td>{item.kyu}</td> : null }
                                                { columns.mc ? <td>{item.mc_no}</td> : null }
                                                { columns.nm ? <td>{item.nmwdt}</td> : null }
                                                { columns.ny ? <td>{item.ny}</td> : null }
                                                { columns.or ? <td>{item.or_file}</td> : null }
                                                { columns.ct ? <td>{item.ct}</td> : null }
                                                { columns.scac ? <td>{item.scac_1}</td> : null }
                                                { columns.scacp ? <td>{item.scac_password}</td> : null }
                                                { columns.ucr ? <td>{item.ucr}</td> : null }
                                                { columns.usdot ? <td>{item.us_dot}</td> : null }
                                                <td style={{padding: '0.3rem'}}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="primary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            { item.is_active ? 
                                                                <Dropdown.Item onClick={() => updateState(item.id, 'archive')}>Archive</Dropdown.Item> : 
                                                                <Dropdown.Item onClick={() => updateState(item.id, 'restore')}>Restore</Dropdown.Item>
                                                            }
                                                            { item.is_restricted ? 
                                                                <Dropdown.Item onClick={() => updateState(item.id, 'allow')}>Allow</Dropdown.Item> : 
                                                                <Dropdown.Item onClick={() => updateState(item.id, 'restrict')}>Restrict</Dropdown.Item>
                                                            }
                                                            <Dropdown.Item as={Link} to={`/truck-list?legal=`+item.legal_name}>Manage Truck</Dropdown.Item>
                                                            <Dropdown.Item as={Link} to={`edit/${item.id}`}>Edit</Dropdown.Item>
                                                            { user.role == 'Admin' ? <Dropdown.Item onClick={() => setDeleteItem({id: item.id, url: 'customers/'+item.id})} className="text-danger">Delete</Dropdown.Item> : null }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />

                                <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
        </div>
    );
}
 
export default Index;