import { useEffect, useState } from "react";
import { store } from '../../redux/store';
import { removeUser } from '../../redux/userSlice';

const ActionPopup = ({setItems, actionItem, setActionItem}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const handleDelete = () => {
        setIsLoading(true);
        setSuccess(null);
        setError(null);

        fetch(actionItem.url, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while perfoming operation, please try again.');
            }

            return response.json();
        })
        .then(data => {
            if (data.status == 'success')
            {
                setSuccess(data.message);
                setItems(current => current.map(value => (value.id == actionItem.id && data?.data) ? data.data : value));
            }
            else
            {
                setError(data.message);
            }
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    return ( 
        <div className="card shadow mb-4" style={{
            position: 'fixed',
            height: 'calc(100vh + 10px)',
            width: 'calc(100vw + 10px)',
            top: '-10px',
            left: '-10px',
            background: '#000000dd',
            display: 'flex',
            zIndex: 100,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <img src={success ? '/tick.svg' : error ? '/cross.svg' : '/confirm.svg'} style={{width: '70px'}} />

            <h4 className="h4 mb-1 mt-2 text-white">
                {isLoading ? 'Please wait...' : success ? 'Success' : error ? 'Error' : 'Confirm Action?'}
            </h4>
            <p className="p mb-3 text-white">
                {isLoading ? 'Please wait while performing action' : success ?? error ?? `Are you sure you want to ${actionItem.type} this?`}
            </p>
            {isLoading ? <div style={{height: 38}}></div> : 
                success ? <button onClick={() => setActionItem(null)} className="btn btn-success btn-submit w-100px mx-2">OK</button> : 
                <div>
                    <button onClick={() => setActionItem(null)} className="btn btn-light btn-submit w-100px mx-2">{error ? 'Cancel' : 'No'}</button>
                    <button onClick={handleDelete} className={`btn btn-${actionItem.buttonClass} btn-submit w-100px mx-2`}>{error ? 'Try Again' : 'Yes'}</button>
                </div>
            }
        </div>
    );
}
 
export default ActionPopup;