import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Filter from '../Filters/Filter';
import useFetch from '../../../useFetch';
import Paginate from '../../../Paginate';
import DeletePopup from '../DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import ActionPopup from '../ActionPopup';

const Index = () => {
    document.title = "Daily Truck Report | Smart Trucking Services";
    
    let navigate = useNavigate();
    
    const [searchParams, setSearchParams] = useSearchParams();

    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/trucks/active');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [sort, setSort] = useState({column: 'legal', type: 'asc'})
    const [apiUrl, setApiUrl] = useState(url);

    const filters = {
        action: apiUrl,
        method: 'GET',
        fields: [
            {
                label: 'Legal Name',
                type: 'input',
                name: 'legal',
            },
            {
                label: 'IRP A/C',
                type: 'select',
                name: 'fleet',
                values: [
                    { text: 'All', value: '' },
                    { text: 'Fleet1', value: 'Fleet1' },
                    { text: 'Fleet2', value: 'Fleet2' },
                    { text: 'Fleet3', value: 'Fleet3' },
                ]
            },
            {
                label: 'Start Date',
                type: 'input',
                subType: 'date',
                name: 'emission_start',
            },
            {
                label: 'End Date',
                type: 'input',
                subType: 'date',
                name: 'emission_end',
            },
            // {
            //     label: 'Search Options',
            //     type: 'select',
            //     name: 'condition',
            //     values: [
            //         { text: 'All', value: '' },
            //         { text: 'New', value: 'new' },
            //         { text: 'Used', value: 'used' },
            //     ]
            // },
        ],
    };

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl, sort);

    useEffect(() => {
        setItems(response?.trucks?.data ?? []);
        setMeta(response?.trucks?.meta ?? []);
    }, [response])

    useEffect(() => {
        for (const [key, value] of searchParams.entries())
        {
            url.searchParams.set(key, value);
        }
        setApiUrl(url);
    }, [searchParams])

    const handleSort = (column) => {
        setSort(sort => {
            var type = 'asc'
            if (sort?.column == column)
            {
                type = sort?.type == 'asc' ? 'desc' : 'asc'
            }

            return {column: column, type: type}
        })

        setCurrentPage(0);
        var url = new URL(apiUrl);
        url.searchParams.delete('page');
        setApiUrl(url);
    }

    return (
        <div className="container-fluid">
            <Filter setApiUrl={setApiUrl} setCurrentPage={setCurrentPage} filters={filters} />
            
            <Card className="shadow mb-4">
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Daily Truck Report</h5>
                    <Button as={Link} target="_blank" to={'print?type=active&'+searchParams.toString()+'&sort='+sort?.column+'&sort_type='+sort?.type} variant="primary" className="btn-sm mr-2">Print</Button>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        items?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <>
                                <Table responsive bordered hover striped>
                                    <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th onClick={() => handleSort('legal')} className={`${sort?.column == 'legal' ? 'sort-'+sort?.type : ''}`}>Legal Name</th>
                                            <th onClick={() => handleSort('date')} className={`${sort?.column == 'date' ? 'sort-'+sort?.type : ''}`}>Date</th>
                                            <th onClick={() => handleSort('vin')} className={`${sort?.column == 'vin' ? 'sort-'+sort?.type : ''}`}>Vin#</th>
                                            <th onClick={() => handleSort('fleet')} className={`${sort?.column == 'fleet' ? 'sort-'+sort?.type : ''}`}>Fleet</th>
                                            <th onClick={() => handleSort('unit')} className={`${sort?.column == 'unit' ? 'sort-'+sort?.type : ''}`}>Unit No</th>
                                            <th onClick={() => handleSort('plate')} className={`${sort?.column == 'plate' ? 'sort-'+sort?.type : ''}`}>Plate No</th>
                                            <th onClick={() => handleSort('emission')} className={`${sort?.column == 'emission' ? 'sort-'+sort?.type : ''}`}>Emission Expiry Date</th>
                                            <th onClick={() => handleSort('or')} className={`${sort?.column == 'or' ? 'sort-'+sort?.type : ''}`}>OR</th>
                                            <th onClick={() => handleSort('ny')} className={`${sort?.column == 'ny' ? 'sort-'+sort?.type : ''}`}>NY</th>
                                            <th onClick={() => handleSort('kyu')} className={`${sort?.column == 'kyu' ? 'sort-'+sort?.type : ''}`}>KYU</th>
                                            <th onClick={() => handleSort('nm')} className={`${sort?.column == 'nm' ? 'sort-'+sort?.type : ''}`}>NM</th>
                                            <th onClick={() => handleSort('ct')} className={`${sort?.column == 'ct' ? 'sort-'+sort?.type : ''}`}>CT</th>
                                            <th onClick={() => handleSort('us')} className={`${sort?.column == 'us' ? 'sort-'+sort?.type : ''}`}>US Decal</th>
                                            <th onClick={() => handleSort('crb')} className={`${sort?.column == 'crb' ? 'sort-'+sort?.type : ''}`}>CARB</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item, index) => (
                                            <tr key={ item.id } style={{
                                                backgroundColor: item.is_duplicate ? '#ffbfbf' : (item.is_duplicate_plate ? '#b4f9b3' : '')
                                            }}>
                                                <td className="bg-dark text-white align-middle text-center">{ (currentPage*75) + index + 1 }</td>
                                                <td><Link to={`/customer-list/edit/${item.customer_id}`}>{ item.legal_name }</Link></td>
                                                <td>{ item.truck_date }</td>
                                                <td><Link to={`/truck-list/edit/${item.id}`}>{ item.vin_no }</Link></td>
                                                <td>{ item.irp_acc }</td>
                                                <td>{ item.unit_no }</td>
                                                <td>{ item.plat_no }</td>
                                                <td className={item.emission_expired ? 'font-weight-bold text-danger' : ''}>{ item.emission_expiry_date }</td>
                                                <td className={`font-weight-bold text-${item.or_file_color}`}>{ item.or_file }</td>
                                                <td className={`font-weight-bold text-${item.ny_color}`}>{ item.ny }</td>
                                                <td className={`font-weight-bold text-${item.kyu_color}`}>{ item.kyu }</td>
                                                <td className={`font-weight-bold text-${item.nmwdt_color}`}>{ item.nmwdt }</td>
                                                <td className={`font-weight-bold text-${item.ct_color}`}>{ item.ct }</td>
                                                <td className={`font-weight-bold text-${item.ucr_color}`}>{ item.ucr }</td>
                                                <td className={`font-weight-bold text-${item.crb_color}`}>{ item.crb }</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />

                                <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
        </div>
    );
}
 
export default Index;