import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Table } from 'react-bootstrap';

const Print = () => {
    document.title = "Customer IRP List | Smart Trucking Services";
    
    const [searchParams, setSearchParams] = useSearchParams();
    
    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/irp-expiry-report-print');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [apiUrl, setApiUrl] = useState(url);

    const [items, setItems] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.customers ?? []);
    }, [response])

    useEffect(() => {
        if (items !== null && items?.length != 0) window.print();
    }, [items])

    return (
        <>
            { items === null ? <h2>Loading data, please wait...</h2> : (
                <Table bordered>
                    <thead>
                        <tr>
                            <th className="text-center">#</th>
                            <th>Legal Name</th>
                            <th>Fleet 1</th>
                            <th>Expiry Date</th>
                            <th>Fleet 2</th>
                            <th>Expiry Date</th>
                            <th>Fleet 3</th>
                            <th>Expiry Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        { items?.map((item, index) => (
                            <tr key={ item.id }>
                                <td className="bg-dark text-white align-middle text-center">{ index + 1 }</td>
                                <td>{ item.legal_name }</td>
                                <td>{ item.irp_acc_1 }</td>
                                <td>{ item.expire_date_1 }</td>
                                <td>{ item.irp_acc_2 }</td>
                                <td>{ item.expire_date_2 }</td>
                                <td>{ item.irp_acc_3 }</td>
                                <td>{ item.expire_date_3 }</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </>
    );
}
 
export default Print;