import * as React from "react";
import { useEffect,useState,useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DataTable,{createTheme} from 'react-data-table-component'
import Apiaddres from '../Apiaddres'
import {useReactToPrint} from  'react-to-print'

import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import Footer from "../../Footer";

export default function DriverList() {
    document.title = "Driver Report | Smart Trucking Services";

    const { user } = useSelector(state => state.user);
    useEffect(() => {
      if (user.role !== 'Admin' && user.username!=="customer") {
          navigate('/unauthorized');
      }
  }, [user]);
  const activeDriver=()=>{
    const filteredArray =data.filter(row => row.archive == 0);
    setFilter(filteredArray);
    setStatus(filteredArray)
    setArchive(0)
}
const inActiveDriver=()=>{
    const filteredArray =data.filter(row => row.archive == 1);
    setFilter(filteredArray);
    setStatus(filteredArray)
    setArchive(1)
}
const allDriver=()=>{
    setFilter(data)
    setStatus(data)
    setArchive('all')
}
  const [listType, setListType] = useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClosePDF = () => {
      setAnchorEl(null);
      DownloadPDF(filter);
    };
 
 const handleCloseCSV= ()=>{
    setAnchorEl(null);
    downloadCSV(filter);
  }
    const handleClose =()=>{
        setAnchorEl(null);

    }
    const navigate = useNavigate();
    const [archive,setArchive] = useState('0')
    const [data,setData] = useState([])
    const [searchdriverid,setSearchDriverid] = useState('')
    const [searchdname,setDname] = useState('')
    const [searchlicence,setLicence] = useState('')
    const [searchCity,setCity] = useState('')
    const [searchcell,setCell] = useState('')
    const [searchcompanyname,setCompany] = useState('')
    const [searchprovince,setProvence] = useState('')
    const [safetymeeting,setSafetyMeeting] = useState('')
    const [filter,setFilter] = useState([])
    const [isShowing, setIsShowing] = useState(true);
    const contetPdf = useRef()
    const generatePdf = useReactToPrint({
        content:()=>contetPdf.current,
  
    })
       {/********************Export to csv functiontion************************ */}
   function convertArrayOfObjectsToCSV(array) {
	let result;

	const columnDelimiter = ',';
	const lineDelimiter = '\n';
	const keys = Object.keys(data[0]);

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;

			result += item[key];
			
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

function downloadCSV(array) {
	const link = document.createElement('a');
	let csv = convertArrayOfObjectsToCSV(array);
	if (csv == null) return;

	const filename = 'export.csv';

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}

	link.setAttribute('href', encodeURI(csv));
	link.setAttribute('download', filename);
	link.click();
}
/**Download Pdf ***************************** */
function convertArrayOfObjectsToPDF(array) {
    const doc = new jsPDF({
        orientation: 'portrait', // 'portrait' or 'landscape'
        unit: 'mm',
        format: 'a4'
    });
    const pageWidth = doc.internal.pageSize.width;
// Define the styles for the theme
const theme = {
    table: { fillColor: [173, 216, 230], lineWidth: 0.1 },
    head: { textColor: [255, 255, 255], lineWidth: 0.1, halign: 'center' ,fillColor: [30, 144, 255], fontSize: 8 },
    body: { textColor: [0, 0, 0], lineWidth: 0.1, fontSize: 8, halign: 'center' },
    alternateRow: { fillColor: [255, 255, 255], padding: 5 }
};


// Add headings or titles
    doc.setFontSize(16);
    const title = "Driver Report";
    const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const titleX = (pageWidth - titleWidth) / 2;
    const marginTop = 10;
    doc.text(title, titleX, 10); // Centered main title

    // Calculate the available width for the table
    const marginLeft = 5; // Margin on the left side
    const marginRight = 5; // Margin on the right side
    const tableWidth = doc.internal.pageSize.width -  marginLeft - marginRight; // Subtracting 10px margin from left and right

    autoTable(doc, {
        head: [['Sr #','Driver Name', 'D.O.B',"Mobile",'City' ,'Province','Dl #','Dl Expiry Date','Medical Expiry Date','Company Name']],
        body: array.map(obj => [
            obj.sr_no,
            obj.first_name,
            obj.dob,
            obj.cell,
            obj.city,
            obj.province,
            obj.driver_licence_no,
            obj.driver_licence_expiry_date,
            obj.driver_medical_expiry_date,
            obj.company_name
        ]),
        theme: 'grid',
        startY: marginTop + 5, // Start the table below the title with margin top and additional 20 units
        margin: { left: marginLeft, right: marginRight }, // Set left and right margin
        tableWidth,
        headStyles: theme.head,
        bodyStyles: theme.body,
        alternateRowStyles: theme.alternateRow
        
      })
   
    return doc;
}

function DownloadPDF(data) {
    const doc = convertArrayOfObjectsToPDF(data);
    doc.save("export.pdf");
}

const[status,setStatus]=useState([]);
//Fetch Driver Record
useEffect(()=>{
    const fetchData = async () => {
        try {
            const response = await fetch(Apiaddres.address+'/alldrivers.php?lname=');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData!=="failed")
            {
                const filteredArray =jsonData.filter(row => row.archive == 0);
                setFilter(filteredArray);
                setData(jsonData)
                setStatus(filteredArray)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
},[])

const [safetydate, setSafetyDate] = useState('')
const [medicalDate, setMedicalDate] = useState('')
const [licenceDate, setLicenceDate] = useState('')
const [annualDate, setAnnualDate] = useState('')
const DriverStatus = (e) => {
    var value = e.target.value
    setListType(e.target.value)
    if (value == "all") {
        allDriver();
    }
    else if (value == "active") {
        activeDriver()
    }
    else {
        inActiveDriver()
    }
    setMedicalDate("default")
    setLicenceDate("default")
    setSafetyDate("default")
    setAnnualDate('default')
}
const safetyDueDate = (e) => {
    var value = e.target.value
    setSafetyDate(value)
    if (value == "expire") {
        safetyExpiry()
    }
    else if(value=="nodata"){
        noSafetyExpiry()
    }
    else if(value=="default"){
        window.location.reload()
    }
    else {
        safetyAboutExpiry()
    }
    setMedicalDate("default")
    setLicenceDate("default")
    setAnnualDate("default")
}
const MedicalDate = (e) => {
    var value = e.target.value
    setMedicalDate(value)
    if (value == "expire") {
        mdicalExpiry()
    }else if(value=="nodata"){
        noMdicalExpiry()
    }
    else if(value=="default"){
        window.location.reload()
    }
    else {
        medicalAboutExpiry()
    }
    setSafetyDate("default")
    setLicenceDate("default")
    setAnnualDate("default")
}
const LicenceDate = (e) => {
    var value = e.target.value
    setLicenceDate(value)
    if (value == "expire") {
        licenceExpiry()
    }
    else if(value=="default"){
        window.location.reload()
    }
    else if(value=="nodata"){
        noLicenceExpiry();
    }
    else {
        licenceAboutExpiry()
    }
    setAnnualDate('default')
    setMedicalDate("default")
    setSafetyDate("default")
}
const AnnualDate = (e) => {
    var value = e.target.value
    setAnnualDate(value)
    if (value == "expire") {
        annualReviewExpiry()
    }
    else if(value=="default"){
        window.location.reload()
    }
    else if(value=="nodata"){
        noAnnualReviewExpiry();
    }
    else {
        annualReviewAboutExpiry()
    }
    setMedicalDate("default")
    setLicenceDate("default")
    setSafetyDate("default")
}
const safetyExpiry = () => {

    const filteredArray = status.filter(row => comparisionOfDates(row.annual_safety_expiry_date));
    const finalfilteredArray = filteredArray.filter(row=> row.annual_safety_expiry_date != null)
    setFilter(finalfilteredArray);
}
const noSafetyExpiry = () => {

    const filteredArray = status.filter(row => comparisionOfDates(row.annual_safety_expiry_date));
    const finalfilteredArray = filteredArray.filter(row=> row.annual_safety_expiry_date == null)
    setFilter(finalfilteredArray);
}
const safetyAboutExpiry = () => {
    const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) > 0);
    setFilter(filteredArray);
}
const mdicalExpiry = () => {

    const filteredArray =status.filter(row => comparisionOfDates(row.driver_medical_expiry_date));
    const finalfilteredArray = filteredArray.filter(row=> row.driver_medical_expiry_date != null)
    setFilter(finalfilteredArray);

}
const noMdicalExpiry = () => {

    const filteredArray =status.filter(row => comparisionOfDates(row.driver_medical_expiry_date));
    const finalfilteredArray = filteredArray.filter(row=> row.driver_medical_expiry_date === null)
    setFilter(finalfilteredArray);

}
const medicalAboutExpiry = () => {
    const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.driver_medical_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_medical_expiry_date)) > 0);
    setFilter(filteredArray);
}
const licenceExpiry = () => {

    const filteredArray = status.filter(row => comparisionOfDates(row.driver_licence_expiry_date));
    const finalfilteredArray = filteredArray.filter(row=> row.driver_licence_expiry_date != null)
    setFilter(finalfilteredArray);

}
const noLicenceExpiry = () => {

    const filteredArray = status.filter(row => comparisionOfDates(row.driver_licence_expiry_date));
    const finalfilteredArray = filteredArray.filter(row=> row.driver_licence_expiry_date === null)
    setFilter(finalfilteredArray);

}
const licenceAboutExpiry = () => {
    const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.driver_licence_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_licence_expiry_date)) > 0);
    setFilter(filteredArray);
}
const annualReviewExpiry = () => {

    const filteredArray = status.filter(row => comparisionOfDates(row.driver_annual_review_due_date));
    const finalfilteredArray = filteredArray.filter(row=> row.driver_annual_review_due_date != null)
    setFilter(finalfilteredArray);

}
const noAnnualReviewExpiry = () => {

    const filteredArray = status.filter(row => comparisionOfDates(row.driver_annual_review_due_date));
    const finalfilteredArray = filteredArray.filter(row=> row.driver_annual_review_due_date === null)
    setFilter(finalfilteredArray);

}
const annualReviewAboutExpiry = () => {
    const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.driver_annual_review_due_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_annual_review_due_date)) > 0);
    setFilter(filteredArray);
}
const columns = [
    {
        name:"#",
        selector:(row)=>row.sr_no,
        sortable:true,
        

    },
    {
        name: " Driver Name ",
        selector: (row) => row.first_name,
        sortable: true,
        cell: (row) => {
            return row.archive == 1 ? (
              <div> <div >
                {row.first_name} {row.last_name}
              </div><br/>
              <span className="badge badge-table badge-danger mr-1">Archived</span>
              </div>
            ) : (
              <div>
                {row.first_name} {row.last_name}
                </div>
            );
          },

    },
    {
        name: " D.O.B. ",
        selector: (row) => row.dob,
        sortable: true
    },
    {
        name: " Mobile ",
        selector: (row) => row.cell,
        sortable: true
    },
    {
        name: " City ",
        selector: (row) => row.city,
        sortable: true
    },
    {
        name: " Province ",
        selector: (row) => row.province,
        sortable: true
    },
    {
        name: " DL No.",
        selector: (row) => row.driver_licence_no,
        sortable: true
    },

    {
        name: " DL Expiry Date ",
        selector: (row) => row.driver_licence_expiry_date,
        sortable: true
    },

    {
        name: " Medical Expiry Date ",
        selector: (row) => row.driver_medical_expiry_date,
        sortable: true
    },
        // {
//     name:" Annual Review Date ",
//     selector:(row)=>row.driver_annual_review_date,
//     sortable:true
// },
{
    name:" Annual Review Due Date ",
    selector:(row)=>row.driver_annual_review_due_date,
    sortable:true
},
// {
//     name:" Safety Meeting Date ",
//     selector:(row)=>row.driver_safety_meeting_date,
//     sortable:true
// },
{
    name:" Safety Meeting Due Date ",
    selector:(row)=>row.driver_meeting_due_date,
    sortable:true
},
    {
        name: " Company Name ",
        selector: (row) => row.company_name,
        sortable: true
    },
    // {
    //     name:" Driver ID",
    //     selector:(row)=>row.d_id,
    //     sortable:true,
    //     cell:(row)=>{return(
    //         <Link to={`/edit-driver/${row.d_id}`} > {row.driver_id} </Link>
    //     )}

    // },
    // {
    //     name:" Last Name ",
    //     selector:(row)=>row.last_name,
    //     sortable:true
    // },

    // {
    //     name:" Address ",
    //     selector:(row)=>row.address,
    //     sortable:true
    // },




    // {
    //     name:" Annual Review Date ",
    //     selector:(row)=>row.driver_annual_review_date,
    //     sortable:true
    // },
    // {
    //     name:" Annual Review Due Date ",
    //     selector:(row)=>row.driver_annual_review_due_date,
    //     sortable:true
    // },
    // {
    //     name:" Safety Meeting Date ",
    //     selector:(row)=>row.driver_safety_meeting_date,
    //     sortable:true
    // },
    // {
    //     name:" Safety Meeting Due Date ",
    //     selector:(row)=>row.driver_meeting_due_date,
    //     sortable:true
    // },



]
const tableHeader = {
    rows: {
		style: {

		},
	},
	headCells: {
		style: {
           
		},
	},
	cells: {
		style: {
            width:'200px'
		},
	},
   
}
const differenceInDays = (date1, date2) => {
    let one_day = 1000 * 60 * 60 * 24;
    let Result = Math.round((date2.getTime() - date1.getTime()) / one_day);
    let Final_Result = Result.toFixed(0);
    console.log(Final_Result)
    return (Final_Result);
};
const comparisionOfDates = (date1)=>
    {
        const currentDate = new Date();
        const dueDate = new Date(date1);
        // Set hours, minutes, seconds, and milliseconds to 0 to compare only dates
        currentDate.setHours(0, 0, 0, 0);
        dueDate.setHours(0, 0, 0, 0);
        return currentDate >= dueDate;
    }

/**conditional Row  Styling ***************** */

const conditionalRowStyles = [
    {
            when: row => row.archive == 1,// is archive
            style: {
                backgroundColor: '#dc3545',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#f0190a',
                    color: 'white',
                },
            },
        },
    {   // Those who are going to be expired
        when: row => differenceInDays(new Date(), new Date(row.driver_meeting_due_date)) <= 30,
        style: {
            backgroundColor: '#ff7bb9',
            color: 'white',
            '&:hover': {
                
                
            },
        },
    },
    { // those who are expired
        when: row => comparisionOfDates(row.driver_meeting_due_date) ,
        style: {
            backgroundColor: '#98cld9',
            color: 'white',
            '&:hover': {
               
            },
        },
    },
    { // those who are expired
        when: row => comparisionOfDates(row.driver_licence_expiry_date) ,
        style: {
            backgroundColor: '#8a5082',
            color: 'white',
            '&:hover': {
               
            },
        },
    },	
    { // those who are expired
        when: row => comparisionOfDates(row.driver_annual_review_due_date) ,
        style: {
            backgroundColor: '#6f5f90',
            color: 'white',
            '&:hover': {
               
            },
        },
    },
    // { // those who are expired
    //     when: row => comparisionOfDates(row.driver_medical_expiry_date) ,
    //     style: {
    //         backgroundColor: '#ee6c4d',
    //         color: 'white',
    //         '&:hover': {
               
    //         },
    //     },
    // },	
];
   // for Sorting 
   useEffect(()=>{
    const result = data.filter((item)=>{
        return item.driver_id.toLowerCase().match(searchdriverid.toLowerCase())
    })
    setFilter(result)
},[searchdriverid])

useEffect(()=>{
    const result = data.filter((item)=>{
        return item.province.toLowerCase().match(searchprovince.toLowerCase())
    })
    setFilter(result)
    setStatus(result)
},[searchprovince])

useEffect(()=>{
    const result = data.filter((item)=>{
        return item.driver_licence_no.toLowerCase().match(searchlicence.toLowerCase())
    })
    setFilter(result)
},[searchlicence])

useEffect(()=>{
    const result = data.filter((item)=>{
        return item.city.toLowerCase().match(searchCity.toLowerCase())
    })
    setFilter(result)
    setStatus(result)
},[searchCity])
 

useEffect(()=>{
    const result = data.filter((item)=>{
        return item.first_name.toLowerCase().match(searchdname.toLowerCase())
    })
    setFilter(result)
    setStatus(result)
},[searchdname])

useEffect(()=>{
    const result = data.filter((item)=>{
        return item.cell.toLowerCase().match(searchcell.toLowerCase())
    })
    setFilter(result)
},[searchcell])


useEffect(()=>{
    const result = data.filter((item)=>{
        return item.company_name.toLowerCase().match(searchcompanyname.toLowerCase())
    })
    setFilter(result)
    setStatus(result)
},[searchcompanyname])


useEffect(()=>{
    const result = data.filter((item)=>{
        return item.driver_safety_meeting_date.toLowerCase().match(safetymeeting.toLowerCase())
    })
    setFilter(result)
},[safetymeeting])

  return (
    <div>
        <div className="container-fluid">
    
    {/********************Filter div ************************ */}
    <Form >
               <Card className="shadow mb-3 filter-card">
                   <Card.Header className="py-2 d-flex align-items-center" style={{height: '50px'}}>
                       <h6 
                           className="h6 mb-0 text-gray1-800 font-weight-bold text-primary mr-3"
                           style={{cursor: 'pointer'}}
                           onClick={() => setIsShowing(!isShowing)}
                           >{isShowing ? 'Hide Filters' : 'Show Filters'}</h6>
                   </Card.Header>
                   {isShowing ? (
                       <Card.Body className="card-body pb-2">
                           <div className="form-row">

                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Driver ID</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchdriverid}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setSearchDriverid(e.target.value)}
                                   
                                   />
                            
                                   
                           </div>

                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Driver Name</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchdname}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setDname(e.target.value)}
                                   
                                   />
                            
                                   
                           </div>
                           
                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Licence #</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchlicence}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setLicence(e.target.value)}                                   
                                   />
                           </div>


                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Cell #</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchcell}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setCell(e.target.value)}                                   
                                   />     
                           </div>


                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Company Name</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchcompanyname}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setCompany(e.target.value)}                                   
                                   />     
                           </div>
                                    <div className="col-xl-3 col-md-6 mb-3"></div>
                                    <div className="col-xl-3 col-md-6 mb-3"></div>
                                    <div className="col-xl-3 col-md-6 mb-3"></div>

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">DL Expiry Date </Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={licenceDate}
                                            onChange={LicenceDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>
                                            <option value='nodata'>No Data</option>

                                        </Form.Select>
                                    </div>
                                   


                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Medical Expiry Date</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={medicalDate}
                                            onChange={MedicalDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>
                                            <option value='nodata'>No Data</option>

                                        </Form.Select>
                                    </div>


                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Annual Review Due Date</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={annualDate}
                                            defaultValue={annualDate}
                                            onChange={AnnualDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>
                                            <option value='nodata'>No Data</option>

                                        </Form.Select>
                                    </div>
                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Safety Meeting Due Date</Form.Label>
                                        <Form.Select
                                            value={safetydate}
                                            onChange={safetyDueDate}
                                            className="form-control"
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>
                                            <option value='nodata'>No Data</option>

                                        </Form.Select>
                                    </div>                        
                           </div>
                           {/* <div style={{display:'flex',flexDirection:'row',marginLeft:'10px'}} >
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#dc3545',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Archive Driver</label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#ff7bb9',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Safety about to  expired </label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#98cld9',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Safety expired </label>
                           </div> 
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#8a5082',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Licence expired</label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#6f5f90',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Review expired</label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#005b96',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Medical expired</label>
                           </div>
                           </div> */}
                       </Card.Body>
                   ) : null}
               </Card>
           </Form>
   
    {/****************************End of the Filter div ********************************8 */}   
   
    {/********************** Header  *****************************************/}
       <Card className="shadow mb-4">
           <Card.Header className="d-flex align-items-center justify-content-between">
               <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Driver Report</h5>
               <div className='d-flex'>
                   <Button onClick={()=>window.location.reload()} variant="primary" className="btn-sm mr-2">Refresh</Button>
                   <Button onClick={generatePdf} variant="primary" className="btn-sm mr-2">Print</Button>
                   <Button onClick={handleClick} variant="primary" className="btn-sm mr-2">Download</Button>
                   <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleClosePDF}>PDF</MenuItem>
                    <MenuItem onClick={handleCloseCSV}>CSV</MenuItem>
                  </Menu>
                   <Form.Select 
                       className="form-control"
                       value={listType ?? 'active'}
                       onChange={DriverStatus}
                       style={{ width: 'auto', fontSize: '14px', height: '30px', padding: '0px 8px' }}
                       >
                       <option  value='all'>All Drivers</option>
                       <option  value='active'>Active Drivers</option>
                       <option  value='archive'>Archived Drivers</option>
                   </Form.Select>
               </div>
           </Card.Header>
   
   {/**************End of Header************************ */}
   
    {/** *************************** My DATA TABLE  ********************************* */}    
       
           <div ref={contetPdf} >
               <DataTable
               customStyles={tableHeader}
               columns={columns}
               data={filter}
               pagination
               
               
               highlightOnHover
           />
   </div>
   
    {/** ***************************END OF My DATA TABLE  ********************************* */}    
   
       </Card>
   </div>
   <Footer/>
    </div>
  )
}
