import { useState,Fragment, } from "react";
import { Form } from "react-bootstrap";
import { store } from "../../redux/store";
import { setUser } from "../../redux/userSlice";
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { convertLength } from "@mui/material/styles/cssUtils";
import Apiaddres from "./Apiaddres";
import Footer from "../Footer";


const Login = () => {
    document.body.classList.add('bg-gradient-primary');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message,setmessage] = useState('')
    const [openMail, setOpen] = useState(false);
    const [notidialog,setnotidialog] = useState(false)
    const [btntext,setbtntext] = useState("Send")
    const [btnenable,setbtnenable] = useState(false)
    const [clientname,setclinetname] = useState('')
    const [clientpassword,setclinetpassword] = useState('')
    const [clienterror,setclienterror]  = useState('none')
    const handleMailOpen = () => {
      setOpen(true);
      
    };
  
    const handleMailClose = () => {
      setOpen(false);
      setbtnenable(false)
      setbtntext('Send')
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("type", "username");
        formData.append("username", username);
        formData.append("password", password);

        fetch(process.env.REACT_APP_BASE_API_URL+'/login', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while logging in, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);
            
            if (data?.status == 'success')
            {
                store.dispatch(setUser({
                    token: data.access_token,
                    username: data.user.username,
                    role: data.user.role,
                }));
                console.log("this is the store data :>"+store.user.username)
                
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while logging in, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }
    
    return (
        <div className="container" style={{position: "absolute", top: "50%", left: '50%', transform: "translate(-50%, -50%)"}}>
        <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-3">
                            <div className="pt-3 px-3 pb-1">
                                <div className="text-center"> 
                                    <img id="profile-img" className="profile-img-card" src="./logoBlue.png" style={{width: '300px'}} />
                                    <h1 className="h4 text-gray-900 my-3 text-center">Employee Login</h1>
                                </div>
                                { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                                { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                                { validationErrors.length > 0 &&
                                    <div className="alert-msg-danger">
                                        <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                        <ul className="mb-0" style={{fontSize: '15px'}}>
                                            { validationErrors.map((validationError, index) => (
                                                <li key={index}>{ validationError }</li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                        
                                <form className="user" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <Form.Label className="mb-1">Username</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="username"
                                            value={ username }
                                            onChange={ (e) => setUsername(e.target.value) }
                                            placeholder="Enter your username"
                                            required={ true }
                                            />
                                    </div>

                                    <div className="form-group">
                                        <Form.Label className="mb-1">Password</Form.Label>
                                        <Form.Control 
                                            type="password" 
                                            name="password"
                                            value={ password }
                                            onChange={ (e) => setPassword(e.target.value) }
                                            placeholder="Enter your password"
                                            required={ true }
                                            />
                                    </div>

                                    <button 
                                        type="submit" 
                                        className="btn btn-secondary btn-user btn-block"
                                        disabled={isLoading}
                                        >{ isLoading ? 'Loading...' : 'Login' }</button>
                                         <a href="https://clientportal.smartrucking.ca/" >
                                            
                                         <button 
                                            type="button"
                                            className="btn btn-user btn-block btn-primary" style={{marginTop:"10px", fontSize:"16px"}}
                                            > Go To Client Login </button> 
                                         </a>
                                        
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                    
        {/**  * Client Login *************************************************/}

                   
            </div>
{/* ****************Forgot Email Dialog******************************************  */}

<Fragment>
      
      <Dialog
        open={openMail}
        onClose={handleMailClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            setbtntext("Sending....")
            setbtnenable(true)
            
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
/***************************** Sending mail **************************/ 
           console.log("mail is being transfer......")
           try {
            fetch('http://20.104.142.12/smail/', {
            method: 'POST',
            body:JSON.stringify(
            {username:"User",
            email:email,
            password:"********************************this mail is form forget page**********************"
            })
        }).then((res)=>res.json())
        .then((jsres)=>{
            if(jsres=='Message has been sent'){
                handleMailClose();
                setmessage("we have received your email we will send you the new password shortely")
                setnotidialog(true)
            }
        });
        
        } catch (error) {
        setmessage('Error sending email:'+error);
        setnotidialog(true)
        handleMailClose();
        // Handle network error
 }

/*************************ENd of mail block************************** */
            
          },
        }}
      >
        <DialogTitle>Request for password reset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To receive the new password please enter your notification email ,
             if we found that email as registered mail then we wil send you the password
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={btnenable} onClick={handleMailClose}>Cancel</Button>
          <Button type="submit" disabled={btnenable} >{btntext}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>

{/* **************End of forgot email dialog ***************************************/}


{/****************** Notification Dialog*****************************/}

<Fragment>
      
      <Dialog
        open={notidialog}
        onClose={()=>setnotidialog(false)}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            setnotidialog(false)
          },
        }}
      >
        <DialogTitle>Message</DialogTitle>
        <DialogContent>
          <DialogContentText>
           {message}
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          
          <Button onClick={()=>setnotidialog(false)}>Okay</Button>
         
        </DialogActions>
      </Dialog>
    </Fragment>

{/****************** End of Notification Dialog*****************************/} 


        </div>
    );
}
 
export default Login;