import * as React from "react";
import { useEffect,useState,useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Apiaddres from "./Apiaddres";
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import DataTable,{createTheme} from 'react-data-table-component'
import {useReactToPrint} from  'react-to-print'
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import Footer from "../Footer";

export default function ClientNotificationAudit() {
    document.title = "Audit Report | Smart Trucking Services";
    const { user } = useSelector(state => state.user);
    useEffect(() => {
      if (user.role !== 'Admin' && user.username!=="customer") {
          navigate('/unauthorized');
      }
  }, [user]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePDF = () => {
    setAnchorEl(null);
    DownloadPDF(filter);
  };

const handleCloseCSV= ()=>{
  setAnchorEl(null);
  downloadCSV(filter);
}
  const handleClose =()=>{
      setAnchorEl(null);

  }
        {/********************Export to csv functiontion************************ */}
        function convertArrayOfObjectsToCSV(array) {
            let result;
        
            const columnDelimiter = ',';
            const lineDelimiter = '\n';
            const keys = Object.keys(data[0]);
        
            result = '';
            result += keys.join(columnDelimiter);
            result += lineDelimiter;
        
            array.forEach(item => {
                let ctr = 0;
                keys.forEach(key => {
                    if (ctr > 0) result += columnDelimiter;
        
                    result += item[key];
                    
                    ctr++;
                });
                result += lineDelimiter;
            });
        
            return result;
        }
        
        function downloadCSV(array) {
            const link = document.createElement('a');
            let csv = convertArrayOfObjectsToCSV(array);
            if (csv == null) return;
        
            const filename = 'export.csv';
        
            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`;
            }
        
            link.setAttribute('href', encodeURI(csv));
            link.setAttribute('download', filename);
            link.click();
        }
      
        /**Downlaod PDF*********************** */
        function convertArrayOfObjectsToPDF(array) {
          const doc = new jsPDF({
              orientation: 'portrait', // 'portrait' or 'landscape'
              unit: 'mm',
              format: 'a4'
          });
          const pageWidth = doc.internal.pageSize.width;
      // Define the styles for the theme
      const theme = {
          table: { fillColor: [173, 216, 230], lineWidth: 0.1 },
          head: { textColor: [255, 255, 255], lineWidth: 0.1, halign: 'center' ,fillColor: [30, 144, 255], fontSize: 8 },
          body: { textColor: [0, 0, 0], lineWidth: 0.1, fontSize: 8, halign: 'center' },
          alternateRow: { fillColor: [255, 255, 255], padding: 5 }
      };
      
      
      // Add headings or titles
          doc.setFontSize(16);
          const title = "Clinet Notification Audit";
          const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          const titleX = (pageWidth - titleWidth) / 2;
          const marginTop = 10;
          doc.text(title, titleX, 10); // Centered main title
      
          // Calculate the available width for the table
          const marginLeft = 5; // Margin on the left side
          const marginRight = 5; // Margin on the right side
          const tableWidth = doc.internal.pageSize.width -  marginLeft - marginRight; // Subtracting 10px margin from left and right
      
          autoTable(doc, {
              head: [['Sr #', 'Company Name','Entity','Reminder Type','Reminder No','Status','Sending Date']],
              body: array.map(obj => [
                  obj.sr_no,
                  obj.customer_name,
                  obj.reminder_entity,
                  obj.reminder_type,
                  obj.reminder_no,
                  obj.status,
                  obj.send_at
              ]),
              theme: 'grid',
              startY: marginTop + 5, // Start the table below the title with margin top and additional 20 units
              margin: { left: marginLeft, right: marginRight }, // Set left and right margin
              tableWidth,
              headStyles: theme.head,
              bodyStyles: theme.body,
              alternateRowStyles: theme.alternateRow
              
            })
         
          return doc;
      }
      
      function DownloadPDF(data) { 
          const doc = convertArrayOfObjectsToPDF(data);
          doc.save("export.pdf");
      }
      const generatePdf = useReactToPrint({
        content:()=>contetPdf.current,
  
    })
    const columns=[
        {
            name:" Sr # ",
            selector:(row)=>row.sr_no,
            sortable:true,
    
    
        },
        {
            name:" Customer Name ",
            selector:(row)=>row.customer_name,
            sortable:true,
            // cell:(row)=>{return(
            //     <Link to={`/edit-trailer/${row.tr_id}`} > {row.trailer_unit} </Link>
            // )}
    
        },
        {
            name:" Entity ",
            selector:(row)=>row.reminder_entity,
            sortable:true
        },
        {
            name:" Entity Id ",
            selector:(row)=>row.reminder_entity_id,
            sortable:true
        },
        {
            name:" Reminder No ",
            selector:(row)=>row.reminder_no,
            sortable:true
        },
        
        {
            name:" Reminder For ",
            selector:(row)=>row.reminder_type,
            sortable:true
        },
        {
            name:" Status ",
            selector:(row)=>row.status,
            sortable:true
        },
        {
            name:" Send At ",
            selector:(row)=>row.send_at,
            sortable:true
        },
        
    
    ]
    const tableHeader = {
        rows: {
            style: {
    
            },
        },
        headCells: {
            style: {
               
            },
        },
        cells: {
            style: {
                maxWidth:'180px'
            },
        },
    }

  const navigate = useNavigate();
  const lname = sessionStorage.getItem("legalName")
  const [data,setData] = useState([])
  const [searchlname,setLname] = useState('')
  const [searchplateno,setPlatno] = useState('')
  const [searchunit,setUnit] = useState('')
  const [filter,setFilter] = useState([])
  const [isShowing, setIsShowing] = useState(true);
  const [archive,setArchive] = useState('0')
  const contetPdf = useRef()
  const [entity,setEnity] = useState('Driver')
  const [reminderFor,setReminderFor] = useState('Licence Expiry')
  const[items,setItems]=useState([{name:"Licence Expiry"},{name:"Medical Expiry"},{name:"Review Expiry"},{name:"Safety Expiry"},])
  const handleEntityChange = (e)=>{
      setEnity(e.target.value)
      if(e.target.value=='Driver'){
          setItems([{name:"Licence Expiry"},{name:"Medical Expiry"},{name:"Review Expiry"},{name:"Safety Expiry"},])
          setReminderFor('Licence Expiry')
      }
     else if(e.target.value=='Trailer'){
          setReminderFor('Next PM Date')
          setItems([{name:"Next PM Date"},{name:"Safety Expiry"},])
      }
      else if(e.target.value=='Truck'){
          setItems([{name:"PM Due Date"},{name:"Safety Expiry"},])
          setReminderFor('PM Due Date')
      }
  }
  //Data Retrival
  useEffect(()=>{
    const fetchData = async () => {
        try {
            const response = await fetch(Apiaddres.address+'/mailaudit.php');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData!="failed"){
                
                setFilter(jsonData);
                setData(jsonData)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
},[])
useEffect(()=>{
    const result = data.filter((item)=>{
        return item.customer_name.toLowerCase().match(searchlname.toLowerCase())
    })
    setFilter(result)
},[searchlname])
useEffect(()=>{
    const result = data.filter((item)=>{
        return item.reminder_entity.toLowerCase().match(entity.toLowerCase())
    })
    setFilter(result)
},[entity])
useEffect(()=>{
    const result = data.filter((item)=>{
        return item.reminder_type.toLowerCase().match(reminderFor.toLowerCase())
    })
    setFilter(result)
},[reminderFor])
  return (
    <div>
    <div className="container-fluid">

{/********************Filter div ************************ */}
<Form >
           <Card className="shadow mb-3 filter-card">
               <Card.Header className="py-2 d-flex align-items-center" style={{height: '50px'}}>
                   <h6 
                       className="h6 mb-0 text-gray1-800 font-weight-bold text-primary mr-3"
                       style={{cursor: 'pointer'}}
                       onClick={() => setIsShowing(!isShowing)}
                       >{isShowing ? 'Hide Filters' : 'Show Filters'}</h6>
               </Card.Header>
               {isShowing ? (
                   <Card.Body className="card-body pb-2">
                       <div className="form-row">

                       <div className="col-xl-2 col-md-6 mb-4">
                            <Form.Label className="mb-1">Entity</Form.Label>
                            <Form.Select 
                            onChange={(e)=>handleEntityChange(e)}
                            className="form-control "
                            >
                            <option value='Driver'>Driver</option>
                            <option value='Trailer'>Trailer</option>
                            <option value='Truck'>Trucks</option>
                        </Form.Select>
                        
                                </div>

                                <div className="col-xl-2 col-md-6 mb-4">
                            <Form.Label className="mb-1">Reminder For</Form.Label>
                            <Form.Select 
                            className="form-control "
                            onChange={(e)=>setReminderFor(e.target.value)}
                            >
                                {items.map((e)=>{
                                    return <option value={e.name} >{e.name} </option>
                                })}
                            {/* 
                            <option value='Medical Expiry'>Med Expiry</option>
                            <option value='Annual Expiry'>Annual Expiry</option>
                            <option value='Safety Expiry'>Safety Expiry</option>
                            <option value='PM Expiry'>PM Expiry</option> */}
                        </Form.Select>
                                </div>

                       <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                       <Form.Label className="mb-1">Legal Name</Form.Label>
                           <Form.Control 
                               type="text"
                               value={searchlname}
                               className='w-25form-control'
                               placeholder='search'
                               onChange={(e)=>setLname(e.target.value)}
                               
                               />
                        
                               
                       </div>
                       
                       </div>
                   </Card.Body>
               ) : null}
           </Card>
       </Form>

{/****************************End of the Filter div ********************************8 */}   

{/********************** Header  *****************************************/}
   <Card className="shadow mb-4">
       <Card.Header className="d-flex align-items-center justify-content-between">
           <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Clinet Notification Audit Report</h5>
           <div className='d-flex'>
               <Button onClick={generatePdf} variant="primary" className="btn-sm mr-2">Print</Button>
               <Button onClick={handleClick} variant="primary" className="btn-sm mr-2">Download</Button>
               <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClosePDF}>PDF</MenuItem>
                <MenuItem onClick={handleCloseCSV}>CSV</MenuItem>
              </Menu>
              
           </div>
       </Card.Header>

{/**************End of Header************************ */}

{/** *************************** My DATA TABLE  ********************************* */}    
   
       <div ref={contetPdf} >
           <DataTable
           customStyles={tableHeader}
           columns={columns}
           data={filter}
           pagination
           selectableRows
        
           selectableRowsHighlight
           highlightOnHover
       />
</div>

{/** ***************************END OF My DATA TABLE  ********************************* */}    

   </Card>
</div>
<Footer/>
</div>
  )
}
