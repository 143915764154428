import { useEffect, useState } from "react";
import { Button, Form, FormCheck, Modal } from "react-bootstrap";
import { store } from '../../../redux/store';
import { removeUser } from '../../../redux/userSlice';

const UpdateComments = ({updateCommentsItem, setUpdateCommentsItem, setItems}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [comments, setComments] = useState()

    const handleSubmit = () => {
        setIsLoading(true);
        setSuccess(null);
        setError(null);

        let formData = new FormData();
        formData.append("comments", comments);
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/truck-update-comments/'+updateCommentsItem.id, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token"),
            },
            body: formData,
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while perfoming operation, please try again.');
            }

            return response.json();
        })
        .then(data => {
            if (data.status == 'success')
            {
                setSuccess(data.message);
                setItems(current => current.map(value => (value.id == updateCommentsItem.id && data?.data) ? data.data : value));
            }
            else
            {
                setError(data.message);
            }
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    return ( 
        <Modal show={updateCommentsItem ? true : false} onHide={() => setUpdateCommentsItem(null)} className="status-modal" centered>
            <Modal.Header closeButton>
                <Modal.Title>Update Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control
                    as="textarea"
                    value={ comments }
                    onChange={ (e) => setComments(e.target.value) }
                    defaultValue={updateCommentsItem.comments}
                    rows={3}
                />
                    
                { error ? <div className="alert-msg-danger mt-2 mb-0"><h3 className="mb-0"><strong>{ error }</strong></h3></div> : null }
                { success ? <div className="alert-msg-success mt-2 mb-0"><h3 className="mb-0"><strong>{ success }</strong></h3></div> : null }
            </Modal.Body>
            <Modal.Footer className={'justify-content-center'}>
                <Button variant="light" size="sm" onClick={() => setUpdateCommentsItem(null)}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" onClick={handleSubmit}>
                    { isLoading ? 'Updating' : 'Update' }
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default UpdateComments;