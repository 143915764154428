import { useEffect, useState } from "react";
import { Button, FormCheck, Modal } from "react-bootstrap";
import { store } from '../../../redux/store';
import { removeUser } from '../../../redux/userSlice';

const UpdateStatus = ({updateStatusItem, setUpdateStatusItem, setItems}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [updateStatus, setUpdateStatus] = useState({
        or_file: false,
        ny: false,
        kyu: false,
        ct: false,
        nmwdt: false,
        ucr: false,
    })

    const handleSubmit = () => {
        setIsLoading(true);
        setSuccess(null);
        setError(null);
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/truck-update-status/'+updateStatusItem.id, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token"),
            },
            body: JSON.stringify(updateStatus),
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while perfoming operation, please try again.');
            }

            return response.json();
        })
        .then(data => {
            if (data.status == 'success')
            {
                setSuccess(data.message);
                setUpdateStatusItem(data.data)
                setItems(current => current.map(value => (value.id == updateStatusItem.id && data?.data) ? data.data : value));
            }
            else
            {
                setError(data.message);
            }
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    return ( 
        <Modal show={updateStatusItem ? true : false} onHide={() => setUpdateStatusItem(null)} className="status-modal" centered>
            <Modal.Header closeButton>
                <Modal.Title>Update Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-wrap mb-n2'>
                    <FormCheck inline type={'checkbox'} className='d-flex align-items-center mr-4 mb-2'
                        label="OR" id={`input-status-or_file_value`} 
                        defaultChecked={updateStatusItem.or_file_value == 2} 
                        disabled={!(updateStatusItem.or_file_value == 1 || updateStatusItem.or_file_value == 3)} 
                        onChange={() => setUpdateStatus(current => ({...current, or_file: !current.or_file}))} />
                    <FormCheck inline type={'checkbox'} className='d-flex align-items-center mr-4 mb-2'
                        label="NY" id={`input-status-ny_value`} 
                        defaultChecked={updateStatusItem.ny_value == 2} 
                        disabled={!(updateStatusItem.ny_value == 1 || updateStatusItem.ny_value == 3)} 
                        onChange={() => setUpdateStatus(current => ({...current, ny: !current.ny}))} />
                    <FormCheck inline type={'checkbox'} className='d-flex align-items-center mr-4 mb-2'
                        label="KYU" id={`input-status-kyu_value`} 
                        defaultChecked={updateStatusItem.kyu_value == 2} 
                        disabled={!(updateStatusItem.kyu_value == 1 || updateStatusItem.kyu_value == 3)} 
                        onChange={() => setUpdateStatus(current => ({...current, kyu: !current.kyu}))} />
                    <FormCheck inline type={'checkbox'} className='d-flex align-items-center mr-4 mb-2'
                        label="CT" id={`input-status-ct_value`} 
                        defaultChecked={updateStatusItem.ct_value == 2} 
                        disabled={!(updateStatusItem.ct_value == 1 || updateStatusItem.ct_value == 3)} 
                        onChange={() => setUpdateStatus(current => ({...current, ct: !current.ct}))} />
                    <FormCheck inline type={'checkbox'} className='d-flex align-items-center mr-4 mb-2'
                        label="NM" id={`input-status-nmwdt_value`} 
                        defaultChecked={updateStatusItem.nmwdt_value == 2} 
                        disabled={!(updateStatusItem.nmwdt_value == 1 || updateStatusItem.nmwdt_value == 3)} 
                        onChange={() => setUpdateStatus(current => ({...current, nmwdt: !current.nmwdt}))} />
                    <FormCheck inline type={'checkbox'} className='d-flex align-items-center mr-4 mb-2'
                        label="US Decal" id={`input-status-ucr_value`} 
                        defaultChecked={updateStatusItem.ucr_value == 2} 
                        disabled={!(updateStatusItem.ucr_value == 1 || updateStatusItem.ucr_value == 3)} 
                        onChange={() => setUpdateStatus(current => ({...current, ucr: !current.ucr}))} />
                </div>

                { error ? <div className="alert-msg-danger mt-2 mb-0"><h3 className="mb-0"><strong>{ error }</strong></h3></div> : null }
                { success ? <div className="alert-msg-success mt-2 mb-0"><h3 className="mb-0"><strong>{ success }</strong></h3></div> : null }
            </Modal.Body>
            <Modal.Footer className={'justify-content-center'}>
                <Button variant="light" size="sm" onClick={() => setUpdateStatusItem(null)}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" onClick={handleSubmit} disabled={isLoading}>
                    { isLoading ? 'Updating' : 'Update' }
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default UpdateStatus;