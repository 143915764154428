import * as React from "react";
import { useEffect,useState,useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DataTable,{createTheme} from 'react-data-table-component'
import Apiaddres from '../Apiaddres'
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {useReactToPrint} from  'react-to-print'
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Footer from "../../Footer";
export default function Trailerlist() {
    const { user } = useSelector(state => state.user);
    useEffect(() => {
      if (user.role !== 'Admin' && user.username!=="customer") {
          navigate('/unauthorized');
      }
  }, [user]);
  document.title = "Trailer Report | Smart Trucking Services";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePDF = () => {
    setAnchorEl(null);
    DownloadPDF(filter);
  };

const handleCloseCSV= ()=>{
  setAnchorEl(null);
  downloadCSV(filter);
}
  const handleClose =()=>{
      setAnchorEl(null);

  }
      {/********************Export to csv functiontion************************ */}
      function convertArrayOfObjectsToCSV(array) {
        let result;
    
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(data[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
    
                result += item[key];
                
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
    }
    
    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
    
        const filename = 'export.csv';
    
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
    
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
  
    /**Downlaod PDF*********************** */
    function convertArrayOfObjectsToPDF(array) {
      const doc = new jsPDF({
          orientation: 'portrait', // 'portrait' or 'landscape'
          unit: 'mm',
          format: 'a4'
      });
      const pageWidth = doc.internal.pageSize.width;
  // Define the styles for the theme
  const theme = {
      table: { fillColor: [173, 216, 230], lineWidth: 0.1 },
      head: { textColor: [255, 255, 255], lineWidth: 0.1, halign: 'center' ,fillColor: [30, 144, 255], fontSize: 8 },
      body: { textColor: [0, 0, 0], lineWidth: 0.1, fontSize: 8, halign: 'center' },
      alternateRow: { fillColor: [255, 255, 255], padding: 5 }
  };
  
  
  // Add headings or titles
      doc.setFontSize(16);
      const title = "Driver Report";
      const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const titleX = (pageWidth - titleWidth) / 2;
      const marginTop = 10;
      doc.text(title, titleX, 10); // Centered main title
  
      // Calculate the available width for the table
      const marginLeft = 5; // Margin on the left side
      const marginRight = 5; // Margin on the right side
      const tableWidth = doc.internal.pageSize.width -  marginLeft - marginRight; // Subtracting 10px margin from left and right
  
      autoTable(doc, {
          head: [['Sr #','Unit No', 'Company Name',"Annual Safety Date",'Annual Safety Expiry Date' ,'Last PM Date','Next PM Date']],
          body: array.map(obj => [
              obj.sr_no,
              obj.trailer_unit,
              obj.company_name,
              obj.last_annual_safety_date,
              obj.annual_safety_expiry_date,
              obj.last_pm_date,
              obj.next_pm_date
          ]),
          theme: 'grid',
          startY: marginTop + 5, // Start the table below the title with margin top and additional 20 units
          margin: { left: marginLeft, right: marginRight }, // Set left and right margin
          tableWidth,
          headStyles: theme.head,
          bodyStyles: theme.body,
          alternateRowStyles: theme.alternateRow
          
        })
     
      return doc;
  }
  
  function DownloadPDF(data) { 
      const doc = convertArrayOfObjectsToPDF(data);
      doc.save("export.pdf");
  }
    const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const lname = sessionStorage.getItem("legalName")
    const [data,setData] = useState([])
    const [searchlname,setLname] = useState('')
    const [searchplateno,setPlatno] = useState('')
    const [searchunit,setUnit] = useState('')
    const [filter,setFilter] = useState([])
    const [isShowing, setIsShowing] = useState(true);
    const [archive,setArchive] = useState('0')
    const contetPdf = useRef()
    const generatePdf = useReactToPrint({
        content:()=>contetPdf.current,
  
    })
    const activeTrailers=()=>{
        const filteredArray =data.filter(row => row.archive == 0);
        setArchive(0)
        setFilter(filteredArray);
        setStatus(filteredArray)
    }
    const inActiveTrailers=()=>{
        const filteredArray =data.filter(row => row.archive == 1);
        setArchive(1)
        setFilter(filteredArray);
        setStatus(filteredArray)
    }
        const allTrailer=()=>{
            setFilter(data)
            setStatus(data)
            setArchive('all')
        }
        
    const[status,setStatus]=useState([]);
    const columns=[
        {
            name:" # ",
            selector:(row)=>row.sr_no,
            sortable:true,
    
    
        },
        {
            name:" Unit # ",
            selector:(row)=>row.trailer_unit,
            sortable:true,
            cell: (row) => {
                return row.archive == 1 ? (
                  <div>  
                    <div  > {row.trailer_unit} </div><br/>
                  <span className="badge badge-table badge-danger mr-1">Archived</span>
                  </div>
                ) : (
                    <div  > {row.trailer_unit} </div>
                );
              },

    
        },
        {
            name:" Last Anual Safety Date ",
            selector:(row)=>row.last_annual_safety_date,
            sortable:true
        },
        {
            name:" Anual Safety Expiry Date ",
            selector:(row)=>row.annual_safety_expiry_date,
            sortable:true
        },
        {
            name:" Last PM Date ",
            selector:(row)=>row.last_pm_date,
            sortable:true
        },
        {
            name:" Next PM Expiry Date ",
            selector:(row)=>row.next_pm_date,
            sortable:true
        },
        {
            name:" Company Name ",
            selector:(row)=>row.company_name,
            sortable:true
        },
        
    
    ]
    const tableHeader = {
        rows: {
            style: {
    
            },
        },
        headCells: {
            style: {
               
            },
        },
        cells: {
            style: {
                width:'200px'
            },
        },
    }
    const [safetyDAte, setSafetyDate] = useState(' ')
    const [pmdate, setPmDate] = useState(' ')
    const TrailerStatus = (e) => {
        var value = e.target.value
        setListType(e.target.value)
        if (value == "all") {
            allTrailer();
        }
        else if (value == "active") {
            activeTrailers()
        }
        else {
            inActiveTrailers()
        }
        setPmDate('default')
        setSafetyDate('default')
    }
    const SafetyDueDate = (e) => {
        var value = e.target.value
        setSafetyDate(value)
        if (value == "expire") {
            safetyExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else if(value=="nodata"){
            noSafetyExpiry()
        }
        else {
            safetyAboutExpiry()
        }
        setPmDate('default')
    }
    const PMDueDate = (e) => {
        var value = e.target.value
        setPmDate(value)
        if (value == "expire") {
            pmExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else if(value=="nodata"){
            noPmExpiry()
        }
        else {
            pmAboutExpiry()
        }
        setSafetyDate('default')
    }
    const pmExpiry = () => {

        const filteredArray = status.filter(row => comparisionOfDates(row.next_pm_date));
        const finalfilteredArray = filteredArray.filter(row=> row.next_pm_date != null)
        setFilter(finalfilteredArray);
    }
    const noPmExpiry = () => {

        const filteredArray = status.filter(row => comparisionOfDates(row.next_pm_date));
        const finalfilteredArray = filteredArray.filter(row=> row.next_pm_date == null)
        setFilter(finalfilteredArray);
    }
    const pmAboutExpiry = () => {

        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.next_pm_date)) <= 30 && differenceInDays(new Date(), new Date(row.next_pm_date)) > 0);
        setFilter(filteredArray);
    }
    const safetyExpiry = () => {
        const filteredArray = status.filter(row => comparisionOfDates(row.annual_safety_expiry_date));
        const finalfilteredArray = filteredArray.filter(row=> row.annual_safety_expiry_date != null)
        setFilter(finalfilteredArray);
       
    }
    const noSafetyExpiry = () => {
        const filteredArray = status.filter(row => comparisionOfDates(row.annual_safety_expiry_date));
        const finalfilteredArray = filteredArray.filter(row=> row.annual_safety_expiry_date == null)
        setFilter(finalfilteredArray);
       
    }
    const safetyAboutExpiry = () => {
        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) > 0);
        setFilter(filteredArray);
    }
    const differenceInDays = (date1, date2) => {
        let one_day = 1000 * 60 * 60 * 24;
        let Result = Math.round((date2.getTime() - date1.getTime()) / one_day);
        let Final_Result = Result.toFixed(0);
       
        return (Final_Result);
    };
    const comparisionOfDates = (date1)=>
        {
            const currentDate = new Date();
            const dueDate = new Date(date1);
            // Set hours, minutes, seconds, and milliseconds to 0 to compare only dates
            currentDate.setHours(0, 0, 0, 0);
            dueDate.setHours(0, 0, 0, 0);
            return currentDate >= dueDate;
        }
    /**conditional Row  Styling ***************** */
    const conditionalRowStyles = [
        {
            when: row => row.archive == 1,
            style: {
                backgroundColor: '#f0190a!important',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#f0190a',
                    color: 'white',
                },
            },
        },
        {
            // those who are goining to be expired 
            when: row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30,
            style: {
                backgroundColor: '#ff7b89',
                color: 'white',
                '&:hover': {
                   
                },
            },
        },
        { //Those who are expired annual_safety_expiry_date
            when: row => comparisionOfDates(row.annual_safety_expiry_date) ,
            style: {
                backgroundColor: '#8a5082',
                color: 'white',
                '&:hover': {
                   
                },
            },
        },
        { //Those who are expired next_pm_date
            when: row => comparisionOfDates(row.next_pm_date) ,
            style: {
                backgroundColor: '#6f5f90',
                color: 'white',
                '&:hover': {
                   
                },
            },
        },
        
    ];
    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await fetch(Apiaddres.address+'/alltrailers.php');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if(jsonData!="failed"){
                    const filteredArray =jsonData.filter(row => row.archive == 0);
                    setFilter(filteredArray);
                    setData(jsonData)
                    setStatus(filteredArray)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    },[])
 // for Sorting 


 useEffect(()=>{
    let filteredArray=[]
   if(archive!='all'){
    filteredArray =data.filter(row => row.archive == archive);
   }
   else{
    filteredArray = data
   }
    const result = filteredArray.filter((item)=>{
        return item.company_name.toLowerCase().match(searchlname.toLowerCase())
    })
    setFilter(result)
    setStatus(result)
},[searchlname])

useEffect(()=>{
    let filteredArray=[]
   if(archive!='all'){
    filteredArray =data.filter(row => row.archive == archive);
   }
   else{
    filteredArray = data
   }
    const result = filteredArray.filter((item)=>{
        return item.trailer_unit.toLowerCase().match(searchunit.toLowerCase())
    })
    setFilter(result)
},[searchunit])




const [listType, setListType] = useState(null);

  return (
    <div>
        <div className="container-fluid">
    
    {/********************Filter div ************************ */}
    <Form >
               <Card className="shadow mb-3 filter-card">
                   <Card.Header className="py-2 d-flex align-items-center" style={{height: '50px'}}>
                       <h6 
                           className="h6 mb-0 text-gray1-800 font-weight-bold text-primary mr-3"
                           style={{cursor: 'pointer'}}
                           onClick={() => setIsShowing(!isShowing)}
                           >{isShowing ? 'Hide Filters' : 'Show Filters'}</h6>
                   </Card.Header>
                   {isShowing ? (
                       <Card.Body className="card-body pb-2">
                           <div className="form-row">

                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Unit #</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchunit}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setUnit(e.target.value)}
                                   
                                   />
                            
                                   
                           </div>

                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Legal Name</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchlname}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setLname(e.target.value)}
                                   
                                   />
                            
                                   
                           </div>
                                    <div className="col-xl-3 col-md-6 mb-3"></div>
                                    <div className="col-xl-3 col-md-6 mb-3"></div>
                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">PM Date </Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={pmdate}
                                            onChange={PMDueDate}
                                        > 
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>
                                            <option value='nodata'>No Data</option>

                                        </Form.Select>
                                    </div>

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Safety Due Date</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={safetyDAte}
                                            onChange={SafetyDueDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>
                                            <option value='nodata'>No Data</option>

                                        </Form.Select>
                                    </div>               
                           </div>
                           {/* <div style={{display:'flex',flexDirection:'row',marginLeft:'10px'}} >
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#dc3545',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Archived</label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#8a5082',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Safety Expired</label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#ff7b89',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Safety about to expire</label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#6f5f90',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >PM Expired</label>
                           </div>
                           
                           </div> */}
                       </Card.Body>
                   ) : null}
               </Card>
           </Form>
   
    {/****************************End of the Filter div ********************************8 */}   
   
    {/********************** Header  *****************************************/}
       <Card className="shadow mb-4">
           <Card.Header className="d-flex align-items-center justify-content-between">
               <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Trailer Report</h5>
               <div className='d-flex'>
                   <Button onClick={()=>window.location.reload()} variant="primary" className="btn-sm mr-2">Refresh</Button>
                   <Button onClick={generatePdf} variant="primary" className="btn-sm mr-2">Print</Button>
                   <Button onClick={handleClick} variant="primary" className="btn-sm mr-2">Download</Button>
                   <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleClosePDF}>PDF</MenuItem>
                    <MenuItem onClick={handleCloseCSV}>CSV</MenuItem>
                  </Menu>
                  
                   <Form.Select 
                       className="form-control"
                       value={listType ?? 'active'}
                       onChange={TrailerStatus}
                       style={{ width: 'auto', fontSize: '14px', height: '30px', padding: '0px 8px' }}
                       >
                     <option value='all'>All Trailers</option>
                     <option value='active'>Active Trailers</option>
                    <option  value='archive'>Archived Trailers</option>
                   </Form.Select>
               </div>
           </Card.Header>
   
   {/**************End of Header************************ */}
   
    {/** *************************** My DATA TABLE  ********************************* */}    
       
           <div ref={contetPdf} >
               <DataTable
               customStyles={tableHeader}
               columns={columns}
               data={filter}
               pagination
               
               highlightOnHover
           />
   </div>
   
    {/** ***************************END OF My DATA TABLE  ********************************* */}    
   
       </Card>
   </div>
   <Footer/>
    </div>
  )
}
