import { useEffect, useState, useRef } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { store } from '../../../redux/store';
import { removeUser } from '../../../redux/userSlice';
import useFetch from '../../../useFetch';
import Input from "./../Forms/Input";
import Select from "./../Forms/Select";
import Textarea from "./../Forms/Textarea";

const Create = () => {
    document.title = "Add Truck Document | Smart Trucking Services";
    
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();
    const fileRef = useRef();
    
    const [trucks, setTrucks] = useState([]);

    const [customer, setCustomer] = useState('');
    const [truck, setTruck] = useState('');
    const [name, setName] = useState('');
    const [comments, setComments] = useState('');
    const [date, setDate] = useState('');
    const [file, setFile] = useState(null);

    const { response: customers } = useFetch(process.env.REACT_APP_BASE_API_URL+'/customer-dropdown');

    useEffect(() => {
        if (!customer) {
            setTrucks([])
            return
        }

        setTrucks(null)

        fetch(process.env.REACT_APP_BASE_API_URL+'/truck-dropdown/'+customer, { 
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('Failed to load data');
            }

            return response.json();
        })
        .then(data => {
            setTrucks(data.trucks);
        })
    }, [customer])
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("truck", truck)
        formData.append("type", '0')
        formData.append("name", name)
        formData.append("comments", comments)
        formData.append("date", date)
        formData.append("file", file)

        fetch(process.env.REACT_APP_BASE_API_URL+'/truck-documents', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while adding document, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);

                setTrucks([])

                setCustomer('');
                setTruck('');
                setName('');
                setComments('');
                setDate('');
                setFile(null);

                if (fileRef?.current)
                {
                    fileRef.current.value = '';
                }
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while adding document, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);
            setError(error.message);
        })
    }

    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <Card ref={cardRef} className="shadow mb-4">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Add Truck Document</h5>
                        <Button onClick={() => navigate(-1)} variant="primary" className="btn-sm">Back</Button>
                    </Card.Header>
                    <Card.Body>
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <div className="form-row">
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Select 
                                    label="Customer *"
                                    value={customer}
                                    setter={setCustomer}
                                    options={[{text: customers?.customers ? 'Select a customer' : 'Loading...', value: ''}, ...(customers?.customers ?? [])]}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Select 
                                    label="Truck *"
                                    value={truck}
                                    setter={setTruck}
                                    options={[{text: trucks != null ? 'Select a truck' : 'Loading...', value: ''}, ...(trucks ?? [])]}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Document Name *"
                                    value={name}
                                    setter={setName}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Document Date"
                                    type={'date'}
                                    value={date}
                                    setter={setDate}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Form.Label className="mb-1">Document File *</Form.Label>
                                <input 
                                    type="file" 
                                    ref={fileRef}
                                    onChange={(e) => setFile(e.target.files[0])}
                                    name="file" 
                                    className="form-control" 
                                    accept=".pdf,.doc,.docx,.xls,.jpeg,.png,.jpg" 
                                    required />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Form.Label className="mb-1">Comments</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={ comments }
                                    onChange={ (e) => setComments(e.target.value) }
                                    rows={3}
                                ></Form.Control>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer className='text-right'>
                        <button 
                            type="submit" 
                            className="btn btn-primary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Loading...' : 'Submit' }</button>
                    </Card.Footer>
                </Card>
            </form>
        </div>
    );
}
 
export default Create;