import { useEffect, useState, useRef } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { useSelector } from 'react-redux';    
// import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
// import 'react-pro-sidebar/dist/css/styles.css'; // Import CSS

const ClientDashboard = ()=>  {
 
  let navigate = useNavigate();
  const { user } = useSelector(state => state.user);
const [open,setOpen] = useState(false)
  useEffect(() => {
    if (user.role !== 'Admin' && user.username!=="customer") {
        navigate('/unauthorized');
    }
}, [user]);
document.title = "Set Customer Password | Smart Trucking Services";

  const handleClick = ()=>{
    navigate("/client-truckdetails")
  }
    return (
    //   <ProSidebar>
    //   <SidebarHeader>
    //     {/* Header content */}
    //   </SidebarHeader>
    //   <SidebarContent>
    //     {/* Sidebar content */}
    //   </SidebarContent>
    //   <SidebarFooter>
    //     {/* Footer content */}
    //   </SidebarFooter>
    // </ProSidebar>
    <div>
    Client dashboeard
    </div>
    )
  
}
export default ClientDashboard
