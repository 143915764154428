import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Table } from 'react-bootstrap';

const Print = () => {
    document.title = "Truck List | Smart Trucking Services";
    
    const [searchParams, setSearchParams] = useSearchParams();
    
    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/trucks-print');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [apiUrl, setApiUrl] = useState(url);

    const [items, setItems] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.trucks ?? []);
    }, [response])

    useEffect(() => {
        if (items !== null && items?.length != 0) window.print();
    }, [items])

    return (
        <>
            { items === null ? <h2>Loading data, please wait...</h2> : (
                <Table bordered>
                    <thead>
                        <tr>
                            <th className="text-center">#</th>
                            <th>Legal Name</th>
                            <th>Phone</th>
                            <th>Date</th>
                            <th>Vin#</th>
                            <th>Fleet</th>
                            <th>Fleet Expiry</th>
                            <th>Unit No</th>
                            <th>Plate No</th>
                            <th>Updated By</th>
                            <th>Updated Date</th>
                            <th>OR</th>
                            <th>NY</th>
                            <th>KYU</th>
                            <th>NM</th>
                            <th>CT</th>
                            <th>US Decal</th>
                        </tr>
                    </thead>
                    <tbody>
                        { items?.map((item, index) => (
                            <tr key={ item.id } style={{
                                backgroundColor: item.is_duplicate ? '#ffbfbf' : (item.is_duplicate_plate ? '#b4f9b3' : '')
                            }}>
                                <td className="bg-dark text-white align-middle text-center">{ index + 1 }</td>
                                <td>
                                    { item.legal_name }
                                    { !item.is_active ? <br /> : null }
                                    { !item.is_active ? <span className={`mb-0 text-danger mr-1`}>Archived</span> : null }
                                </td>
                                <td>{ item.telephone }</td>
                                <td>{ item.truck_date }</td>
                                <td>{ item.vin_no }</td>
                                <td>{ item.irp_acc }</td>
                                <td>{ item.fleet_expiry }</td>
                                <td style={{ backgroundColor: '#01315A', color: '#ffffff' }}>{ item.unit_no }</td>
                                <td>{ item.plat_no }</td>
                                <td>{ item.last_updated_by }</td>
                                <td>{ item.last_updated_ts }</td>
                                <td className={`font-weight-bold text-${item.or_file_color}`}>{ item.or_file }</td>
                                <td className={`font-weight-bold text-${item.ny_color}`}>{ item.ny }</td>
                                <td className={`font-weight-bold text-${item.kyu_color}`}>{ item.kyu }</td>
                                <td className={`font-weight-bold text-${item.nmwdt_color}`}>{ item.nmwdt }</td>
                                <td className={`font-weight-bold text-${item.ct_color}`}>{ item.ct }</td>
                                <td className={`font-weight-bold text-${item.ucr_color}`}>{ item.ucr }</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </>
    );
}
 
export default Print;