import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useFetch from '../../../useFetch';
import DeletePopup from '../DeletePopup';
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import ActionPopup from '../ActionPopup';
import { useSelector } from 'react-redux';

const Index = () => {
    let navigate = useNavigate();
    const { user } = useSelector(state => state.user);
    
    useEffect(() => {
        if (user.role !== 'Admin') {
            navigate('/unauthorized');
        }
    }, [user]);

    document.title = "User List | Smart Trucking Services";    
    
    const [sort, setSort] = useState({column: 'name', type: 'asc'})
    const [apiUrl, setApiUrl] = useState(new URL(process.env.REACT_APP_BASE_API_URL+'/users'))

    const [items, setItems] = useState([]);
    const [actionItem, setActionItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl, sort);

    useEffect(() => {
        setItems(response?.users?.data ?? []);
    }, [response])

    const updateState = (id, type) => {
        var url = '';
        var buttonClass = 'danger';
        if (type == 'deactivate') {
            url = process.env.REACT_APP_BASE_API_URL+'/user-archive/'+id
        }
        else if (type == 'activate') {
            url = process.env.REACT_APP_BASE_API_URL+'/user-restore/'+id
            buttonClass = 'success'
        }

        if (url) 
        {
            setActionItem({id: id, type: type, url: url, buttonClass: buttonClass})
        }
    }

    const handleSort = (column) => {
        setSort(sort => {
            var type = 'asc'
            if (sort?.column == column)
            {
                type = sort?.type == 'asc' ? 'desc' : 'asc'
            }

            return {column: column, type: type}
        })
    }

    return (
        <div className="container-fluid">
            {actionItem ? <ActionPopup setItems={setItems} actionItem={actionItem} setActionItem={setActionItem} /> : null}
            {deleteItem ? <DeletePopup setItems={setItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            
            <Card className="shadow mb-4">
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">User List</h5>
                    <div className='d-flex'>
                        <Button as={Link} to={'create'} variant="primary" className="btn-sm mr-2">Add New</Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        items?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <>
                                <Table responsive bordered hover striped>
                                    <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th onClick={() => handleSort('name')} className={`${sort?.column == 'name' ? 'sort-'+sort?.type : ''}`}>Full Name</th>
                                            <th onClick={() => handleSort('id')} className={`${sort?.column == 'id' ? 'sort-'+sort?.type : ''}`}>User ID</th>
                                            <th onClick={() => handleSort('type')} className={`${sort?.column == 'type' ? 'sort-'+sort?.type : ''}`}>User Type</th>
                                            <th onClick={() => handleSort('customer')} className={`${sort?.column == 'customer' ? 'sort-'+sort?.type : ''}`}>Customer</th>
                                            <th style={{width: '1px' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item, index) => (
                                            <tr key={ item.id } style={{
                                                backgroundColor: !item.is_active ? '#ffbfbf' : ''
                                            }}>
                                                <td className="bg-dark text-white align-middle text-center">{ index + 1 }</td>
                                                <td>{ item.name }</td>
                                                <td>{ item.code }</td>
                                                <td>{ item.role_name }</td>
                                                <td>{ item.legal_name }</td>
                                                <td style={{padding: '0.3rem'}}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="primary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            { item.is_active ? 
                                                                <Dropdown.Item onClick={() => updateState(item.id, 'deactivate')}>Deactivate</Dropdown.Item> : 
                                                                <Dropdown.Item onClick={() => updateState(item.id, 'activate')}>Activate</Dropdown.Item>
                                                            }
                                                            <Dropdown.Item as={Link} to={`edit-password/${item.id}`}>Change Password</Dropdown.Item>
                                                            <Dropdown.Item as={Link} to={`edit/${item.id}`}>Edit</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => setDeleteItem({id: item.id, url: 'users/'+item.id})} className="text-danger">Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
        </div>
    );
}
 
export default Index;