import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Table } from 'react-bootstrap';

const Print = () => {
    document.title = "IFTA List | Smart Trucking Services";
    
    const [searchParams, setSearchParams] = useSearchParams();
    
    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/customer-iftas-print');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [apiUrl, setApiUrl] = useState(url);

    const [items, setItems] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.customerIftas ?? []);
    }, [response])

    useEffect(() => {
        if (items !== null && items?.length != 0) window.print();
    }, [items])

    return (
        <>
            { items === null ? <h2>Loading data, please wait...</h2> : (
                <Table bordered>
                    <thead>
                        <tr>
                            <th className="text-center">#</th>
                            <th>Legal Name</th>
                            <th>Phone</th>
                            <th>Invoice No</th>
                            <th>OR</th>
                            <th>OR Amount</th>
                            <th>NY</th>
                            <th>NY Amount</th>
                            <th>KY</th>
                            <th>KY Amount</th>
                            <th>NM</th>
                            <th>NM Amount</th>
                            <th>CT</th>
                            <th>CT Amount</th>
                            <th>US Decal</th>
                            <th>US DecalAmount</th>
                        </tr>
                    </thead>
                    <tbody>
                        { items?.map((item, index) => (
                            <tr key={ item.id }>
                                <td className="bg-dark text-white align-middle text-center">{ index + 1 }</td>
                                <td>{ item.legal_name }</td>
                                <td>{ item.telephone }</td>
                                <td>{ item.invoice_no }</td>
                                <td>{ item.or }</td>
                                <td>{ item.or_amount }</td>
                                <td>{ item.ny }</td>
                                <td>{ item.ny_amount }</td>
                                <td>{ item.kyu }</td>
                                <td>{ item.kyu_amount }</td>
                                <td>{ item.nmwdt }</td>
                                <td>{ item.nmwdt_amount }</td>
                                <td>{ item.ct }</td>
                                <td>{ item.ct_amount }</td>
                                <td>{ item.ucr }</td>
                                <td>{ item.ucr_amount }</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </>
    );
}
 
export default Print;