
import React, { Component } from 'react'

export default class Apiaddres extends Component {
 static address = "https://smartrucking.ca/backend/customer_apis"
static mailapi="https://smartrucking.ca/coremail/"
//  static address = "http://localhost/st/backend/Customer_apis"
//  static mailapi="http://localhost/smail/"
}

// forgwt mail ,edit customer,add customer,setcostomer pass,clientlogin,auto mail,client reports (truck,drivers,trailers) 20.104.142.12 