import { Form } from "react-bootstrap";

const Input = ({ label, name, value, onChange, required, type }) => {
    const handleChange = (e) => {
        onChange(e);
    }

    return ( 
        <>
            <Form.Label className="mb-1">{ label }</Form.Label>
            <Form.Control 
                type={type ?? "text"}
                name={ name } 
                value={ value }
                onChange={ handleChange }
                required={required ? true : false}
                />
        </>
     );
}
 
export default Input;