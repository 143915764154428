import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Filter from '../Filters/Filter';
import useFetch from '../../../useFetch';
import Paginate from '../../../Paginate';
import DeletePopup from '../DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Form, FormCheck, Image, Modal, Table } from 'react-bootstrap';

const Index = () => {
    document.title = "IFTA List | Smart Trucking Services";
    
    let navigate = useNavigate();
    
    const [searchParams, setSearchParams] = useSearchParams();
    
    /*
    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/customer-iftas');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const filters = {
        action: apiUrl,
        method: 'GET',
        fields: [
            {
                label: 'Client Name',
                type: 'select',
                name: 'legal',
                values: [
                    { text: 'All', value: '' },
                    { text: 'New', value: 'new' },
                    { text: 'Used', value: 'used' },
                ]
            },
            {
                label: 'Trade Name',
                type: 'select',
                name: 'trade',
                values: [
                    { text: 'All', value: '' },
                    { text: 'New', value: 'new' },
                    { text: 'Used', value: 'used' },
                ]
            },
        ],
    };
    */

    const [customer, setCustomer] = useState('');
    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]); 
    const [currentPage, setCurrentPage] = useState(0);

    const { response: customers } = useFetch(process.env.REACT_APP_BASE_API_URL+'/customer-dropdown-iftas');
    
    const [sort, setSort] = useState({column: 'legal', type: 'asc'})
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/customer-iftas?customer=');
    const { response, isLoading, error: fetchError } = useFetch(apiUrl, sort);

    useEffect(() => {
        setItems(response?.customerIftas?.data ?? []);
        setMeta(response?.customerIftas?.meta ?? []);
    }, [response])

    useEffect(() => {
        if (customer === null) return

        var newUrl = new URL(process.env.REACT_APP_BASE_API_URL+'/customer-iftas?customer='+customer);

        setCurrentPage(0);
        setApiUrl(newUrl)
    }, [customer])

    /*
    useEffect(() => {
        for (const [key, value] of searchParams.entries())
        {
            url.searchParams.set(key, value);
        }
        setApiUrl(url);
    }, [searchParams])
    */

    const handleSort = (column) => {
        setSort(sort => {
            var type = 'asc'
            if (sort?.column == column)
            {
                type = sort?.type == 'asc' ? 'desc' : 'asc'
            }

            return {column: column, type: type}
        })

        setCurrentPage(0);
        var url = new URL(apiUrl);
        url.searchParams.delete('page');
        setApiUrl(url);
    }

    return (
        <div className="container-fluid">
            {/* <Filter setApiUrl={setApiUrl} setCurrentPage={setCurrentPage} filters={filters} /> */}
            
            <Card className="shadow mb-4">
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <div className='d-flex align-items-center'>
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3" style={{ minWidth: '80px' }}>IFTA List</h5>
                        <Form.Select 
                            className="form-control"
                            value={customer ?? ''}
                            onChange={(e) => setCustomer(e.target.value)}
                            style={{ maxWidth: '230px', fontSize: '14px', height: '30px', padding: '0px 8px' }}
                            >
                            { [{text: customers?.customers ? 'All customers' : 'Loading...', value: ''}, ...(customers?.customers ?? [])].map(value => (
                                <option value={value.value}>{value.text}</option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className='d-flex'>
                        <Button as={Link} target="_blank" to={'print?customer='+customer+'&sort='+sort?.column+'&sort_type='+sort?.type} variant="primary" className="btn-sm mr-2">Print</Button>
                        <Button as={Link} to={'create'} variant="primary" className="btn-sm">Add New</Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        items?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <>
                                <Table responsive bordered hover striped>
                                    <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th onClick={() => handleSort('legal')} className={`${sort?.column == 'legal' ? 'sort-'+sort?.type : ''}`}>Legal Name</th>
                                            <th onClick={() => handleSort('phone')} className={`${sort?.column == 'phone' ? 'sort-'+sort?.type : ''}`}>Phone</th>
                                            <th onClick={() => handleSort('invoice')} className={`${sort?.column == 'invoice' ? 'sort-'+sort?.type : ''}`}>Invoice No</th>
                                            <th onClick={() => handleSort('or')} className={`${sort?.column == 'or' ? 'sort-'+sort?.type : ''}`}>OR</th>
                                            <th onClick={() => handleSort('or_amount')} className={`${sort?.column == 'or_amount' ? 'sort-'+sort?.type : ''}`}>OR Amount</th>
                                            <th onClick={() => handleSort('ny')} className={`${sort?.column == 'ny' ? 'sort-'+sort?.type : ''}`}>NY</th>
                                            <th onClick={() => handleSort('ny_amount')} className={`${sort?.column == 'ny_amount' ? 'sort-'+sort?.type : ''}`}>NY Amount</th>
                                            <th onClick={() => handleSort('kyu')} className={`${sort?.column == 'kyu' ? 'sort-'+sort?.type : ''}`}>KY</th>
                                            <th onClick={() => handleSort('kyu_amount')} className={`${sort?.column == 'kyu_amount' ? 'sort-'+sort?.type : ''}`}>KY Amount</th>
                                            <th onClick={() => handleSort('nmwdt')} className={`${sort?.column == 'nmwdt' ? 'sort-'+sort?.type : ''}`}>NM</th>
                                            <th onClick={() => handleSort('nmwdt_amount')} className={`${sort?.column == 'nmwdt_amount' ? 'sort-'+sort?.type : ''}`}>NM Amount</th>
                                            <th onClick={() => handleSort('ct')} className={`${sort?.column == 'ct' ? 'sort-'+sort?.type : ''}`}>CT</th>
                                            <th onClick={() => handleSort('ct_amount')} className={`${sort?.column == 'ct_amount' ? 'sort-'+sort?.type : ''}`}>CT Amount</th>
                                            <th onClick={() => handleSort('ucr')} className={`${sort?.column == 'ucr' ? 'sort-'+sort?.type : ''}`}>US Decal</th>
                                            <th onClick={() => handleSort('ucr_amount')} className={`${sort?.column == 'ucr_amount' ? 'sort-'+sort?.type : ''}`}>US DecalAmount</th>
                                            <th style={{width: '1px' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item, index) => (
                                            <tr key={ item.id }>
                                                <td className="bg-dark text-white align-middle text-center">{ (currentPage*75) + index + 1 }</td>
                                                <td><Link to={`/customer-list/edit/${item.customer_id}`}>{ item.legal_name }</Link></td>
                                                <td>{ item.telephone }</td>
                                                <td><Link to={`edit/${item.id}`}>{ item.invoice_no }</Link></td>
                                                <td>{ item.or }</td>
                                                <td>{ item.or_amount }</td>
                                                <td>{ item.ny }</td>
                                                <td>{ item.ny_amount }</td>
                                                <td>{ item.kyu }</td>
                                                <td>{ item.kyu_amount }</td>
                                                <td>{ item.nmwdt }</td>
                                                <td>{ item.nmwdt_amount }</td>
                                                <td>{ item.ct }</td>
                                                <td>{ item.ct_amount }</td>
                                                <td>{ item.ucr }</td>
                                                <td>{ item.ucr_amount }</td>
                                                <td style={{padding: '0.3rem'}}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="primary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as={Link} to={`edit/${item.id}`}>Edit</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />

                                <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
            
        </div>
    );
}
 
export default Index;