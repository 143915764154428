import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Filter from '../Filters/Filter';
import useFetch from '../../../useFetch';
import Paginate from '../../../Paginate';
import DeletePopup from '../DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Form, FormCheck, Image, Modal, Table } from 'react-bootstrap';
import ActionPopup from '../ActionPopup';

const Index = () => {
    document.title = "Truck Document List | Smart Trucking Services";
    
    let navigate = useNavigate();
    
    const [searchParams, setSearchParams] = useSearchParams();
    
    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/truck-documents');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [sort, setSort] = useState({column: 'document', type: 'asc'})
    const [apiUrl, setApiUrl] = useState(url);

    const filters = {
        action: apiUrl,
        method: 'GET',
        fields: [
            {
                label: 'Legal Name',
                type: 'input',
                name: 'legal',
            },
            {
                label: 'Vin',
                type: 'input',
                name: 'vin',
            },
            {
                label: 'Document Name',
                type: 'input',
                name: 'name',
            },
            {
                label: 'Document Comments',
                type: 'input',
                name: 'comments',
            },
            // {
            //     label: 'Search Options',
            //     type: 'select',
            //     name: 'condition',
            //     values: [
            //         { text: 'All', value: '' },
            //         { text: 'New', value: 'new' },
            //         { text: 'Used', value: 'used' },
            //     ]
            // },
        ],
    };

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [deleteItem, setDeleteItem] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl, sort);

    useEffect(() => {
        setItems(response?.documents?.data ?? []);
        setMeta(response?.documents?.meta ?? []);
    }, [response])

    useEffect(() => {
        for (const [key, value] of searchParams.entries())
        {
            url.searchParams.set(key, value);
        }
        setApiUrl(url);
    }, [searchParams])

    const handleSort = (column) => {
        setSort(sort => {
            var type = 'asc'
            if (sort?.column == column)
            {
                type = sort?.type == 'asc' ? 'desc' : 'asc'
            }

            return {column: column, type: type}
        })

        setCurrentPage(0);
        var url = new URL(apiUrl);
        url.searchParams.delete('page');
        setApiUrl(url);
    }

    return (
        <div className="container-fluid">
            {deleteItem ? <DeletePopup setItems={setItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            <Filter setApiUrl={setApiUrl} setCurrentPage={setCurrentPage} filters={filters} />
            
            <Card className="shadow mb-4">
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Truck Document List</h5>
                    <Button as={Link} to={'create'} variant="primary" className="btn-sm">Add New</Button>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        items?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <>
                                <Table responsive bordered hover striped>
                                    <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th onClick={() => handleSort('legal')} className={`${sort?.column == 'legal' ? 'sort-'+sort?.type : ''}`}>Legal Name</th>
                                            <th onClick={() => handleSort('vin')} className={`${sort?.column == 'vin' ? 'sort-'+sort?.type : ''}`}>Vin</th>
                                            <th onClick={() => handleSort('document')} className={`${sort?.column == 'document' ? 'sort-'+sort?.type : ''}`}>Document Name</th>
                                            <th onClick={() => handleSort('date')} className={`${sort?.column == 'date' ? 'sort-'+sort?.type : ''}`}>Document Date</th>
                                            <th onClick={() => handleSort('updated')} className={`${sort?.column == 'updated' ? 'sort-'+sort?.type : ''}`}>Updated By</th>
                                            <th onClick={() => handleSort('comments')} className={`${sort?.column == 'comments' ? 'sort-'+sort?.type : ''}`}>Comments</th>
                                            <th>View</th>
                                            <th style={{width: '1px' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item, index) => (
                                            <tr key={ item.id }>
                                                <td className="bg-dark text-white align-middle text-center">{ (currentPage*75) + index + 1 }</td>
                                                <td><Link to={`/customer-list/edit/${item.customer_id}`}>{ item.legal_name }</Link></td>
                                                <td><Link to={`/truck-list/edit/${item.truck_id}`}>{ item.vin_no }</Link></td>
                                                <td>{ item.name }</td>
                                                <td>{ item.date }</td>
                                                <td>{ item.last_updated_by }</td>
                                                <td>{ item.comments }</td>
                                                <td style={{padding: '0.3rem', width: '1px'}}>
                                                    <Button variant="primary" href={item.full_path} className="btn-sm">View</Button>
                                                </td>
                                                <td style={{padding: '0.3rem', width: '1px'}}>
                                                    <Button variant="danger" onClick={() => setDeleteItem({id: item.id, url: 'truck-documents/'+item.id})} className="btn-sm">Delete</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />

                                <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
        </div>
    );
}
 
export default Index;