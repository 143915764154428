import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';    
import Input from "./Forms/Input";
import { json, useNavigate } from "react-router-dom";
import { Button, Card, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Apiaddres from './Apiaddres';
import Footer from '../Footer';

export default function Automailer() {
    let navigate = useNavigate();
    const { user } = useSelector(state => state.user);
    
    useEffect(() => {
        if (user.role !== 'Admin') {
            navigate('/unauthorized');
        }
    }, [user]);
    useEffect(()=>{
        fetch(Apiaddres.address+'/automaillist.php').
        then((res)=>res.json()).
        then((jsres)=>{
            setData(jsres)
        })
    },[])
    document.title = "Auto Mailer | Smart Trucking Services";
    const cardRef = useRef();
    const [savedialogalter,setsavedialogalert] = useState('none')
    const [entity,setEnity] = useState('Driver')
    const [reminderFor,setReminderFor] = useState('Licence Expiry')
    const[reminder1,SetReminder1] = useState('30')
    const[reminder2,SetReminder2] = useState('14')
    const[reminder3,SetReminder3] = useState('7')
    const[reminder4,SetReminder4] = useState('1')
    const[data,setData] = useState([])
    const[items,setItems]=useState([{name:"Licence Expiry"},{name:"Medical Expiry"},{name:"Review Expiry"},{name:"Safety Expiry"},])
    const handleEntityChange = (e)=>{
        setEnity(e.target.value)
        if(e.target.value=='Driver'){
            setItems([{name:"Licence Expiry"},{name:"Medical Expiry"},{name:"Review Expiry"},{name:"Safety Expiry"},])
            setReminderFor('Licence Expiry')
        }
       else if(e.target.value=='Trailer'){
            setReminderFor('Next PM Date')
            setItems([{name:"Next PM Date"},{name:"Safety Expiry"},])
        }
        else if(e.target.value=='Truck'){
            setItems([{name:"PM Due Date"},{name:"Safety Expiry"},])
            setReminderFor('PM Due Date')
        }
    }
    const handleSave = ()=>{
        var call = 'addautomailer.php'
        data.forEach(element => {
            if(element.entity==entity & element.reminder_for==reminderFor){
                call='editautomailer.php'
            }
            
        });

        fetch(Apiaddres.address+'/'+call, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                entity:entity,
                reminder_for:reminderFor,
                reminder1:reminder1,
                reminder2:reminder2,
                reminder3:reminder3,
                reminder4:reminder4
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data=="success"){
                window.location.reload()
            } // Output response from the PHP script
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
    const columns=[
        {
            name: " Sr no ",
            selector: (row) => row.sr_no,
            sortable: true,
        },
        {
            name: " Entity ",
            selector: (row) => row.entity,
            sortable: true,
        },
        {
            name: " Reminder For ",
            selector: (row) => row.reminder_for,
            sortable: true,
        },
        {
            name: " Reminder 1",
            selector: (row) => row.reminder1,
            sortable: true,
        },
        {
            name: " Reminder 2 ",
            selector: (row) => row.reminder2,
            sortable: true,
        },
        {
            name: " Reminder 3",
            selector: (row) => row.reminder3,
            sortable: true,
        },
        {
            name: " Reminder 4 ",
            selector: (row) => row.reminder4,
            sortable: true,
        },
        {
            name: " Delete ",
            selector: (row) => row.am_id,
            sortable: true,
            cell: (row) => <button onClick={()=>handleDelete(row.am_id)}>Delete</button>,
		    ignoreRowClick: true,
        },
    ]
    const tableHeader = {
        rows: {
            style: {

            },
        },
        headCells: {
            style: {

            },
        },
        cells: {
            style: {
              
                maxWidth: '100px'
            },
        },

    }
    const handleDelete = (id)=>{
        fetch(Apiaddres.address+'/delautomail.php?id='+id).
        then((res)=>res.json()).
        then((jsres)=>{
            if(jsres=='okay'){
                setsavedialogalert('inline-block')
                
            }
        })
    }
  return (
    <div className="container-fluid">
            <form>
                <Card ref={cardRef} className="shadow mb-4">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Client Notification Scheduler</h5>
                        <Button onClick={() => navigate(-1)} variant="primary" className="btn-sm">Back</Button>
                    </Card.Header>
                    <Card.Body> 

                        <fieldset className='mb-4'>
                            <legend>Reminder Setup</legend>
                            <div className="form-row">

                            <div className="col-xl-2 col-md-6 mb-4">
                            <Form.Label className="mb-1">Entity</Form.Label>
                            <Form.Select 
                            onChange={(e)=>handleEntityChange(e)}
                            className="form-control "
                            >
                            <option value='Driver'>Driver</option>
                            <option value='Trailer'>Trailer</option>
                            <option value='Truck'>Trucks</option>
                        </Form.Select>
                        
                                </div>

                                <div className="col-xl-2 col-md-6 mb-4">
                            <Form.Label className="mb-1">Reminder For</Form.Label>
                            <Form.Select 
                            className="form-control "
                            onChange={(e)=>setReminderFor(e.target.value)}
                            >
                                {items.map((e)=>{
                                    return <option value={e.name} >{e.name} </option>
                                })}
                            {/* 
                            <option value='Medical Expiry'>Med Expiry</option>
                            <option value='Annual Expiry'>Annual Expiry</option>
                            <option value='Safety Expiry'>Safety Expiry</option>
                            <option value='PM Expiry'>PM Expiry</option> */}
                        </Form.Select>
                                </div>

                              
                                <div className="col-xl-2 col-md-6 mb-4">
                                    <Input
                                        label="First Reminder"
                                        value={reminder1}
                                        setter={SetReminder1}
                                        required={true}
                                        />
                                </div>
                                <div className="col-xl-2 col-md-6 mb-4">
                                    <Input
                                        label="Second Reminder"
                                        value={reminder2}
                                        setter={SetReminder2}
                                        required={true}
                                        />
                                </div>
                                <div className="col-xl-2 col-md-6 mb-4">
                                    <Input
                                        label="Third Reminder"
                                        value={reminder3}
                                        setter={SetReminder3}
                                        required={true}
                                        />
                                </div>
                                <div className="col-xl-2 col-md-6 mb-4">
                                    <Input
                                        label="Forth Reminder"
                                        value={reminder4}
                                        setter={SetReminder4}
                                        required={true}
                                        />
                                </div>

                            </div>
                        </fieldset>

                                    </Card.Body>
                    <Card.Footer className='text-right'>
                        <button 
                            type="button" 
                            className="btn btn-primary btn-submit w-100px"
                            onClick={handleSave}
                            >Save</button>
                    </Card.Footer>
                </Card>
            </form>
            <DataTable
            data={data}
            columns={columns}
            customStyles={tableHeader}
            />

<div style={{display:savedialogalter}} >
             <div>
         <div className="card shadow mb-4" style={{
             position: 'fixed',
             height: 'calc(100vh + 10px)',
             width: 'calc(100vw + 10px)',
             top: '-10px',
             left: '-10px',
             background: '#000000dd',
             display: 'flex',
             zIndex: 100,
             justifyContent: 'center',
             alignItems: 'center',
         }}>
         
             <h4 className="h4 mb-1 mt-2 text-white">
                 Note
             </h4>
             <div > <p className='text-white' >Schedule Deleted   </p> </div>
                 
                 <div>
                     <button onClick={()=>{setsavedialogalert('none');window.location.reload()}}  className="btn btn-success btn-submit w-100px mx-2">Okay</button>
                 </div>
             
         </div>
         </div>
             </div>
             <Footer/>
        </div>
  )
}
