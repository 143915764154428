import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Filter from '../Filters/Filter';
import useFetch from '../../../useFetch';
import Paginate from '../../../Paginate';
import DeletePopup from '../DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import ActionPopup from '../ActionPopup';

const Print = () => {
    document.title = "Daily Truck Report | Smart Trucking Services";
    
    const [searchParams, setSearchParams] = useSearchParams();

    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/trucks-print');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [apiUrl, setApiUrl] = useState(url);

    const [items, setItems] = useState(null);
    
    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.trucks ?? []);
    }, [response])

    useEffect(() => {
        if (items !== null && items?.length != 0) window.print();
    }, [items])

    return (
        <>
            { items === null ? <h2>Loading data, please wait...</h2> : (
                <Table bordered>
                    <thead>
                        <tr>
                            <th className="text-center">#</th>
                            <th>Legal Name</th>
                            <th>Date</th>
                            <th>Vin#</th>
                            <th>Fleet</th>
                            <th>Unit No</th>
                            <th>Plate No</th>
                            <th>Emission Expiry Date</th>
                            <th>OR</th>
                            <th>NY</th>
                            <th>KYU</th>
                            <th>NM</th>
                            <th>CT</th>
                            <th>US Decal</th>
                            <th>CARB</th>
                        </tr>
                    </thead>
                    <tbody>
                        { items?.map((item, index) => (
                            <tr key={ item.id } style={{
                                backgroundColor: item.is_duplicate ? '#ffbfbf' : (item.is_duplicate_plate ? '#b4f9b3' : '')
                            }}>
                                <td className="bg-dark text-white align-middle text-center">{ index + 1 }</td>
                                <td>{ item.legal_name }</td>
                                <td>{ item.truck_date }</td>
                                <td>{ item.vin_no }</td>
                                <td>{ item.irp_acc }</td>
                                <td>{ item.unit_no }</td>
                                <td>{ item.plat_no }</td>
                                <td className={item.emission_expired ? 'font-weight-bold text-danger' : ''}>{ item.emission_expiry_date }</td>
                                <td className={`font-weight-bold text-${item.or_file_color}`}>{ item.or_file }</td>
                                <td className={`font-weight-bold text-${item.ny_color}`}>{ item.ny }</td>
                                <td className={`font-weight-bold text-${item.kyu_color}`}>{ item.kyu }</td>
                                <td className={`font-weight-bold text-${item.nmwdt_color}`}>{ item.nmwdt }</td>
                                <td className={`font-weight-bold text-${item.ct_color}`}>{ item.ct }</td>
                                <td className={`font-weight-bold text-${item.ucr_color}`}>{ item.ucr }</td>
                                <td className={`font-weight-bold text-${item.crb_color}`}>{ item.crb }</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </>
    );
}
 
export default Print;