import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "./redux/store";
import { removeUser } from "./redux/userSlice";

const useFetch = (url, sort) => {
    let navigate = useNavigate();
    const [response, setResponse] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        setIsLoading(true);
        setError(null);

        var apiUrl = url
        if (sort) {
            apiUrl += (apiUrl?.search != '' ? '&' : '?')+'sort='+sort?.column+'&sort_type='+sort?.type
        }

        fetch(apiUrl, { 
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('Failed to load data');
            }

            return response.json();
        })
        .then(data => {
            setResponse(data);
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }, [url, sort]);

    return {response, isLoading, error};
}
 
export default useFetch;