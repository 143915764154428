import { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import '../assets/css/sb-admin-2.min.css';
import '../assets/css/styles.css';

import Login from "./Login";
import NotFound from "./NotFound";
import Dashboard from "./Dashboard";

import UserIndex from './Users/Index';
import UserCreate from './Users/Create';
import UserEdit from './Users/Edit';
import UserPassword from './Users/Password';
import CustomerIndex from './Customers/Index';
import CustomerPrint from './Customers/Print';
import SafetyPrint from "./SafetyCustomers/SafetyPrint";
import CustomerCreate from './Customers/Create';
import CustomerEdit from './Customers/Edit'; 
import CustomerDocumentIndex from './CustomerDocuments/Index';
import CustomerDocumentCreate from './CustomerDocuments/Create';
import TruckIndex from './Trucks/Index';
import TruckPrint from './Trucks/Print';
import TruckCreate from './Trucks/Create';
import TruckEdit from './Trucks/Edit';
import TruckDocumentIndex from './TruckDocuments/Index';
import TruckDocumentCreate from './TruckDocuments/Create';
import CustomerIftaIndex from './CustomerIftas/Index';
import CustomerIftaPrint from './CustomerIftas/Print';
import CustomerIftaCreate from './CustomerIftas/Create';
import CustomerIftaEdit from './CustomerIftas/Edit';
import IrpExpiryReportIndex from './IrpExpiryReports/Index';
import IrpExpiryReportPrint from './IrpExpiryReports/Print';
import DailyTruckReportIndex from './DailyTruckReports/Index';
import DailyTruckReportPrint from './DailyTruckReports/Print';
import SafetyCustomerIndex from './SafetyCustomers/Index';
import CvorReportIndex from './CvorReports/Index';
import LoginLogIndex from './LoginLogs/Index';
import CvorUpdateIndex from './CvorUpdates/Index';
import PermitUpdateIndex from './PermitUpdates/Index';

import Navbar from "./Navbar";
import Unauthorized from "./Unauthorized";
import { useSelector } from "react-redux";
import ClientDashboard from "./ClientSide/ClientDashboard";
import SetcustomerPassword from "./Customers/SetCustomerPassword";
import ClientTruckdetails from "./ClientSide/ClientTruckdetails";
import Trailerlist from "./CleintReport/Trailerlist";
import DriverList from "./CleintReport/DriverList";
import TruckLIst from "./CleintReport/TruckLIst";
import HazmatCustomer from "./SafetyCustomers/HazmatCustomer";
import Automailer from "./Automailer";
import ClientNotificationAudit from "./ClientNotificationAudit";

const App = () => {
    return ( 
        <Routes>
            <Route path="customer-list/print" element={<CustomerPrint />} />
            <Route path="truck-list/print" element={<TruckPrint />} />
            <Route path="daily-truck-report/print" element={<DailyTruckReportPrint />} />
            <Route path="customer-iftas/print" element={<CustomerIftaPrint />} />
            <Route path="safety-customers-report/print" element={<SafetyPrint />} />
            <Route path="irp-expiry-report/print" element={<IrpExpiryReportPrint />} />
 
            <Route element={<AppLayout />} >
                <Route path="/" element={<Dashboard />} />
                <Route path="user-list" element={<UserIndex />} />
                <Route path="user-list/create" element={<UserCreate />} />
                <Route path="user-list/edit/:id" element={<UserEdit />} />
                <Route path="user-list/edit-password/:id" element={<UserPassword />} /> 
                
                <Route path="customer-list" element={<CustomerIndex />} />
                <Route path="customer-list/create" element={<CustomerCreate />} />
                <Route path="customer-list/edit/:id" element={<CustomerEdit />} />
                <Route path="customer-password" element={ <SetcustomerPassword/> } />
                
                <Route path="customer-document-list" element={<CustomerDocumentIndex />} />
                <Route path="customer-document-list/create" element={<CustomerDocumentCreate />} />
                
                <Route path="truck-list" element={<TruckIndex />} />
                <Route path="truck-list/create" element={<TruckCreate />} />
                <Route path="truck-list/edit/:id" element={<TruckEdit />} />
                
                <Route path="truck-document-list" element={<TruckDocumentIndex />} />
                <Route path="truck-document-list/create" element={<TruckDocumentCreate />} />

                <Route path="customer-iftas" element={<CustomerIftaIndex />} />
                <Route path="customer-iftas/create" element={<CustomerIftaCreate />} />
                <Route path="customer-iftas/edit/:id" element={<CustomerIftaEdit />} />

                <Route path="irp-expiry-report" element={<IrpExpiryReportIndex />} />
                <Route path="daily-truck-report" element={<DailyTruckReportIndex />} />
                <Route path="safety-customers-report" element={<SafetyCustomerIndex />} />
                <Route path="hazmat-customers-report" element={<HazmatCustomer />} />
                <Route path="cvor-report" element={<CvorReportIndex />} />
                <Route path="login-logs" element={<LoginLogIndex />} />

                <Route path="update-cvor" element={<CvorUpdateIndex />} />
                <Route path="update-permit" element={<PermitUpdateIndex />} />
                
                <Route path="client-dashboard" element={<ClientDashboard/>} />
                <Route path="client-trailerlist" element={<Trailerlist/>} />
                <Route path="client-driverlist" element={<DriverList/>} />
                <Route path="client-trucklist" element={<TruckLIst/>} />
                <Route path="auto-mailer" element={<Automailer/>} />
                <Route path="client-notification-audit" element={<ClientNotificationAudit/>} />

                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

const AppLayout = () => {
    const { user } = useSelector(state => state.user);
    
    const clientToken = localStorage.getItem("clientToken");
    if (!user?.token)
    {
        document.title = "Login | Smart Trucking Services";
        return (
            <Login />
        )
    }
   
    else{
        {/*************************** user panel ***************************** */}
        
        return (
            <div className={`admin-app app-sidebar-toggled`} id="wrapper">
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar user={user} />
                        <Outlet />
                    </div>
                </div>
            </div>
        )
    }
   
    
    
    };
 
export default App;