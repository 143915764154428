import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { store } from "../../redux/store";
import { removeUser } from "../../redux/userSlice";
import { useState } from "react";

const NavbarAdmin = ({user}) => {
    const currentPath = useLocation().pathname.split('/')[1];
    let navigate = useNavigate();
        
    const handleLogout = () => {
        fetch(process.env.REACT_APP_BASE_API_URL+'/logout', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })

        store.dispatch(removeUser());
        navigate('/');
    }
    const handleSubmit=()=>{
        setPasswordPage('none')
    }
const [passwordPage,setPasswordPage] = useState('none')
    return ( 
       < div>
        <nav className="navbar navbar-expand navbar-light topbar mb-3 static-top shadow" style={{ backgroundColor: '#01315A' }}>
            <Navbar.Brand>
                <img src="https://www.smartrucking.ca/images/logo.png"/>
            </Navbar.Brand>
            <ul className="navbar-nav">
                { user.role == 'Admin' ? <Nav.Link as={Link} to={"/user-list"}>Users</Nav.Link> : null }
                <NavDropdown title="Customers">
                    <NavDropdown.Item as={Link} to={"/customer-list"}>Customer List</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/customer-list/create"}>Add New Customer</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/customer-document-list"}>Customer Document List</NavDropdown.Item>
                    <NavDropdown.Item as={Link}  to={"/customer-document-list/create"}>Add Customer Document</NavDropdown.Item>
                    { user.role == 'Admin' ? <NavDropdown.Item as={Link} to={"/customer-password"}>Set Customer Password</NavDropdown.Item> : null }
                   
                </NavDropdown>
                <NavDropdown title="Trucks">
                    <NavDropdown.Item as={Link} to={"/truck-list"}>Truck List</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/truck-list/create"}>Add New Truck</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/truck-document-list"}>Truck Document List</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/truck-document-list/create"}>Add Truck Document</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Reports">
                    <NavDropdown.Item as={Link} to={"/irp-expiry-report"}>IRP Expiry Report</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/daily-truck-report"}>Daily Truck Report</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/customer-iftas"}>Customer IFTA</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/safety-customers-report"}>Safety Customers</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/hazmat-customers-report"}>Hazmat Customers</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/cvor-report"}>CVOR Report</NavDropdown.Item>
                    { user.role == 'Admin' ? <NavDropdown.Item as={Link} to={"/login-logs"}>Login Logs</NavDropdown.Item> : null }
                </NavDropdown>
                <NavDropdown title="Updates/Changes">
                    <NavDropdown.Item as={Link} to={"/update-cvor"}>CVOR Status Update</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/update-permit"}>Permits Update</NavDropdown.Item>
                </NavDropdown>

                 <NavDropdown title="Client Portal Data">
                    <NavDropdown.Item as={Link} to={"/client-trucklist"}>Client Trucks</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/client-driverlist"}>Client Drivers</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/client-trailerlist"}>Client Trailers</NavDropdown.Item> 
                    { user.role == 'Admin' ? <NavDropdown.Item as={Link} to={"/auto-mailer"}>Client Notification Scheduler</NavDropdown.Item> : null }
                    <NavDropdown.Item as={Link} to={"/client-notification-audit"}>Client Notification Audit</NavDropdown.Item> 
                </NavDropdown> 

            </ul>
            <ul className="navbar-nav ml-auto">
                <NavDropdown title={user?.username} >
                    <NavDropdown.Item onClick={handleLogout} > Logout </NavDropdown.Item>
                </NavDropdown>
              
            </ul>
           
        </nav>
           {/** Message Box Save Check */}

           {/* <div style={{display:passwordPage}} >
           <div>
     <div className="card shadow mb-4" style={{
           position: 'fixed',
           height: 'calc(100vh + 10px)',
           width: 'calc(100vw + 10px)',
           top: '-10px',
           left: '-10px',
           background: '#000000dd',
           display: 'flex',
           zIndex: 100,
           justifyContent: 'center',
           alignItems: 'center',
       }}>
           <img src='/confirm.svg' style={{width: '70px'}} />

           <h4 className="h4 mb-1 mt-2 text-white">
               Set Customer Password
           </h4>
           <div > <input  /> </div>
               
               <div>
                   <button   className="btn btn-success btn-submit w-100px mx-2">Okay</button>
               </div>
           
       </div>
   </div>
           </div> */}
           </div>
     );
}
 
export default NavbarAdmin;