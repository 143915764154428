import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Table } from 'react-bootstrap';

const Print = () => {
    document.title = "Customer List | Smart Trucking Services";
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [columns, setColumns] = useState({
        cc1: false,
        cvor: false,
        ifta: false,
        kyu: false,
        ct: false,
        mc: false,
        nm: false,
        ny: false,
        or: false,
        scac: false,
        scacp: false,
        ucr: false,
        usdot: false,
    })

    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/customers-print');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
        columns[key] = true
    }

    const [apiUrl, setApiUrl] = useState(url);

    const [items, setItems] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.customers ?? []);
    }, [response])

    useEffect(() => {
        if (items !== null && items?.length != 0) window.print();
    }, [items])

    return (
        <>
            { items === null ? <h2>Loading data, please wait...</h2> : (
                <Table bordered>
                    <thead>
                        <tr>
                            <th className="text-center">#</th>
                            <th>Legal Name</th>
                            <th>Trade Name</th>
                            <th>Customer Name</th>
                            <th>Telephone#</th>
                            <th>Cellphone#</th>
                            <th>Fax#</th>
                            <th>Email</th>
                            { columns.cc1 ? <th>Carrier Code</th> : null }
                            { columns.cvor ? <th>CVOR No</th> : null }
                            { columns.ifta ? <th>IFTA No</th> : null }
                            { columns.kyu ? <th>KY No</th> : null }
                            { columns.mc ? <th>MC No</th> : null }
                            { columns.nm ? <th>NM No</th> : null }
                            { columns.ny ? <th>NY No</th> : null }
                            { columns.or ? <th>OR No</th> : null }
                            { columns.ct ? <th>CT No</th> : null }
                            { columns.scac ? <th>SCAC No</th> : null }
                            { columns.scacp ? <th>SCAC Pwd</th> : null }
                            { columns.ucr ? <th>UCR No</th> : null }
                            { columns.usdot ? <th>US Dot No</th> : null }
                        </tr>
                    </thead>
                    <tbody>
                        { items?.map((item, index) => (
                            <tr key={ item.id }>
                                <td className="bg-dark text-white align-middle text-center">{ index + 1 }</td>
                                <td>
                                    { item.legal_name }
                                    { !item.is_active || item.is_restricted ? <br /> : null }
                                    { !item.is_active ? <span className={`mb-0 text-danger mr-1`}>Archived</span> : null }
                                    { item.is_restricted ? <span className={`mb-0 text-warning mr-1`}>Restricted</span> : null }
                                </td>
                                <td>{ item.trade_name }</td>
                                <td>{ item.customer_name }</td>
                                <td>{ item.telephone }</td>
                                <td>{ item.cell }</td>
                                <td>{ item.fax }</td>
                                <td>{ item.email }</td>
                                { columns.cc1 ? <td>{item.carrier_code_1}</td> : null }
                                { columns.cvor ? <td>{item.cvor}</td> : null }
                                { columns.ifta ? <td>{item.ifta_acc}</td> : null }
                                { columns.kyu ? <td>{item.kyu}</td> : null }
                                { columns.mc ? <td>{item.mc_no}</td> : null }
                                { columns.nm ? <td>{item.nmwdt}</td> : null }
                                { columns.ny ? <td>{item.ny}</td> : null }
                                { columns.or ? <td>{item.or_file}</td> : null }
                                { columns.ct ? <td>{item.ct}</td> : null }
                                { columns.scac ? <td>{item.scac_1}</td> : null }
                                { columns.scacp ? <td>{item.scac_password}</td> : null }
                                { columns.ucr ? <td>{item.ucr}</td> : null }
                                { columns.usdot ? <td>{item.us_dot}</td> : null }
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </>
    );
}
 
export default Print;