import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import Input from "./Input";
import Select from "./Select";

const Filter = (props) => {
    const filters = props.filters;

    const [isShowing, setIsShowing] = useState(props?.isShowing ?? true);
    const [inputs, setInputs] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {        
        filters.fields.map((filter) => {
            setInputs(values => ({...values, [filter.name]: searchParams.get(filter.name) || filter.default || ''}))
        });

        if (filters.sort)
            setInputs(values => ({...values, [filters.sort.name]: searchParams.get(filters.sort.name) ||filters.sort.default || ''}))
    }, [searchParams])

    const handleSubmit = (e) => {
        e.preventDefault();

        props.setCurrentPage(0);

        var url = new URL(filters.action);
        url.searchParams.delete('page');

        var params = {};

        for(var key in inputs)
        {
            if (inputs[key])
            {
                params = {...params, [key]: inputs[key]};
                url.searchParams.set(key, inputs[key]);
            }
            else
                url.searchParams.delete(key);
        }
        
        setSearchParams(params);

        props.setApiUrl(url);
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    return ( 
        <Form onSubmit={ handleSubmit }>
            <Card className="shadow mb-3 filter-card">
                <Card.Header className="py-2 d-flex align-items-center" style={{height: '50px'}}>
                    <h6 
                        className="h6 mb-0 text-gray1-800 font-weight-bold text-primary mr-3"
                        style={{cursor: 'pointer'}}
                        onClick={() => setIsShowing(!isShowing)}
                        >{isShowing ? 'Hide Filters' : 'Show Filters'}</h6>
                    {isShowing ? (<Button type="submit" variant="primary" className="ml-auto px-3 btn-sm">Submit</Button>) : null}
                </Card.Header>
                {isShowing ? (
                    <Card.Body className="card-body pb-2">
                        <div className="form-row">
                            { filters.fields.map((filter) => (
                                <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                    { filter.type == 'input' && 
                                        <Input 
                                            label={filter.label} 
                                            type={filter?.subType}
                                            name={filter.name} 
                                            value={ inputs[filter.name] } 
                                            onChange={handleChange} /> }
                                    
                                    { filter.type == 'select' && 
                                        <Select 
                                            label={filter.label} 
                                            name={filter.name} 
                                            value={ inputs[filter.name] } 
                                            options={filter.values} 
                                            onChange={handleChange} /> }
                                </div>
                            )) }
                            { filters.sort &&
                                <div key="filter-sort" className="col-xl-3 col-md-6 mb-3">
                                    <Select 
                                        label={filters.sort.label} 
                                        name={filters.sort.name} 
                                        value={ inputs[filters.sort.name] } 
                                        options={filters.sort.values} 
                                        onChange={handleChange} />
                                </div>
                            }
                        </div>
                    </Card.Body>
                ) : null}
            </Card>
        </Form>
     );
}
 
export default Filter;