import { useEffect, useState, useRef } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { json, useNavigate } from "react-router-dom";
import { store } from '../../../redux/store';
import { removeUser } from '../../../redux/userSlice';
import useFetch from '../../../useFetch';
import Input from "./../Forms/Input";
import Select from "./../Forms/Select";
import { useSelector } from 'react-redux';
import Apiaddres from '../Apiaddres';
import Footer from '../../Footer';
import TextInput from 'react-autocomplete-input';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import 'react-autocomplete-input/dist/bundle.css';
const SetcustomerPassword = () => {
    let navigate = useNavigate();
    const { user } = useSelector(state => state.user);

    useEffect(() => {
        if (user.role !== 'Admin') {
            navigate('/unauthorized');
        }
    }, [user]);
    document.title = "Set Customer Password | Smart Trucking Services";
    const [listType, setListType] = useState(null);

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    var url = new URL(process.env.REACT_APP_BASE_API_URL + '/customers/' + (listType ?? 'active'));
    const [sort, setSort] = useState({ column: 'legal', type: 'asc' })
    const [apiUrl, setApiUrl] = useState(url);
    const { response, isLoading, error: fetchError } = useFetch(apiUrl, sort);

    useEffect(() => {//
        const fetchData = async () => {
            try {
                const response = await fetch(Apiaddres.address + '/allcustomer.php');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data != "failed") {
                    if (data != null) {
                        
                        var customerNames = [];
                        data.map((item) => {
                            customerNames.push(item.legal_name)
                        })
                        
                        setItems(customerNames)
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();


    }, [])

    const cardRef = useRef();
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [showsavedialog, setsavedialog] = useState('none')
    const [dialogmessage, setmessage] = useState('')
    const [foundcustomer, setfoundcustomer] = useState('none')
    const [lname, setlname] = useState('')
    const [dialogmessage2, setdialogmessage2] = useState('')
    const [errormessage, setErrormMessage] = useState('')
    const handleSearchCustomer = (e) => {
        e.preventDefault()
        if (lname === '') {
            //setsavedialog('inline-block')
            setErrormMessage("No ligal name found.")
            return
        }
        console.log(lname)
        fetch(Apiaddres.address + "/searchcustomer.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({ l_name: lname }),
        }).
            then((res) => res.json())
            .then((jsres) => {
                let response = jsres
                if (response !== 'failed') {
                    setfoundcustomer('flex')
                    setErrormMessage('')
                    setUserId(response)
                }
                else {
                    console.log(response)
                    setfoundcustomer('none')

                    setErrormMessage('Sorry, Either the customer does not exist, or this customer is not a safety account.')
                }
            })

    }
    const handleAutocompleteChange = (event, newValue) => {
        if (newValue) {
            setlname(newValue);
        } else {
            setlname(""); // If newValue is null (i.e., no suggestion is selected), reset lname
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        if (password == '' || password2 == '') {
            setsavedialog('inline-block')
            setmessage('Password must not empty')
            return
        }
        if (password !== password2) {
            setsavedialog('inline-block')
            setmessage('Password must match.')
            return
        }
        /////////////////////////////////////////////////////////////////////////
        // Define regular expressions for each criteria
        const lengthRegex = /.{8,}/;
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const digitRegex = /\d/;
        //const specialCharRegex = /[^A-Za-z0-9]/;   && hasSpecialChar

        // Check each criteria
        const isLengthValid = lengthRegex.test(password);
        const hasUppercase = uppercaseRegex.test(password);
        const hasLowercase = lowercaseRegex.test(password);
        const hasDigit = digitRegex.test(password);
        //const hasSpecialChar = specialCharRegex.test(password);

        // Check if all criteria are met
        const isStrongPassword = isLengthValid && hasUppercase && hasLowercase && hasDigit;
        if (!isStrongPassword) {
            setmessage("Password must be at least 8 characters long and includes uppercase, lowercase and digits.")
            setsavedialog('inline-block')
            return
        }        ///////////////////////////////////////////////////////
        fetch(Apiaddres.address + '/updatecustomerpass.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({ password: password, user_id: userId }),
        }).
            then((res) => res.json()).
            then((jsres) => {
                //let response = JSON.stringify(jsres)
                if (jsres == 'okay') {
                    /** ************************* Sending mail *********************** */
                    setdialogmessage2("Passowrd updated now sending the email.....")
                    try {
                        var message = "Dear " + lname + ",<br><br>This is an automated e-mail response for your recent password change request for your SmartTrucking Consultants Client Portal..<br><br><br> New Password: " + password + "</b><br><br>Thank you,<br><br>Smart Trucking Consultants";
                        var subject = "Smart Trucking Service's New Password"
                        fetch(Apiaddres.mailapi + 'inde.php', {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    username: "User",
                                    email: userId,
                                    password: password,
                                    message: message,
                                    subject: subject
                                })
                        }).then((res) => res.json())
                            .then((jsres) => {
                                setsavedialog('inline-block')
                                setmessage("Password Updated Successfully and " + JSON.stringify(jsres))
                            });

                    } catch (error) {
                        setmessage('Error sending email:' + error);
                        // Handle network error
                    }

                    /*************************ENd of mail block************************** */
                }


            })




    }

    return (
        <>
            <div className="container-fluid">
                <form onSubmit={handleSearchCustomer} >
                    <Card ref={cardRef} className="shadow mb-4">
                        <Card.Header className="d-flex align-items-center justify-content-between">
                            <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Set Customer  Password</h5>
                            <Button onClick={() => navigate(-1)} variant="primary" className="btn-sm">Back</Button>
                        </Card.Header>
                        <Card.Body>

                            <fieldset className='mb-3'>
                                <legend>Reset Password</legend>
                                <div className="form-row">
                                    <div className="col-xl-4 col-md-6 mb-3">
                                        {/* <Input
                                        label="Legal Name"
                                        value={lname}
                                        setter={setlname}
                                        required={true}
                                        /> */}
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={items}
                                            sx={{ width: 300 }}
                                            value={lname} // Set the value to lname
                                            onChange={handleAutocompleteChange} // Handle change event
                                            renderInput={(params) => <TextField {...params} label="Legal Name" />}

                                        />
                                    </div>

                                </div>
                            </fieldset>

                        </Card.Body>
                        <Card.Footer className='text-right'>
                            <label style={{ width: '100%', textAlign: 'center', color: 'red' }} > {errormessage} </label>
                            <button
                                type="submit"
                                className="btn btn-primary btn-submit w-100px"
                            >Search Customer</button>
                        </Card.Footer>
                    </Card>
                </form>
            </div>
            {/**************************************** Found Customer div ******************************************/}
            <div style={{ display: foundcustomer }} >

                <div className="container-fluid">
                    <form onSubmit={handleSubmit}>
                        <Card ref={cardRef} className="shadow mb-4">
                            <Card.Body>

                                <fieldset className='mb-3'>
                                    <legend>Reset Password</legend>
                                    <div className="form-row">
                                        <div className="col-xl-4 col-md-6 mb-3">
                                            <Input
                                                label="User Id"
                                                value={userId}
                                                setter={setUserId}
                                                required={true}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-xl-4 col-md-6 mb-3">
                                            <Form.Label className="mb-1">Password</Form.Label>
                                            <Form.Control
                                                type='password'

                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                title='Password must contain at least one number, one uppercase and one lowercase letter, and be at least 8 characters long'

                                            />
                                        </div>
                                        <div className="col-xl-4 col-md-6 mb-3">
                                            <Form.Label className="mb-1"> Confrim Password</Form.Label>
                                            <Form.Control
                                                type='password'

                                                value={password2}
                                                onChange={(e) => setPassword2(e.target.value)}
                                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                title='Password must contain at least one number, one uppercase and one lowercase letter, and be at least 8 characters long'

                                            />
                                        </div>
                                    </div>
                                </fieldset>

                            </Card.Body>
                            <Card.Footer className='text-right'>
                                <label style={{ width: '100%', textAlign: 'center', color: 'red' }} > {dialogmessage2} </label>
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-submit w-100px"
                                >Send Email</button>
                            </Card.Footer>
                        </Card>
                    </form>
                </div>
            </div>

            {/* *********************************  Message Box  Disable ****************************************/}
            <div style={{ display: showsavedialog }} >
                <div>
                    <div className="card shadow mb-4" style={{
                        position: 'fixed',
                        height: 'calc(100vh + 10px)',
                        width: 'calc(100vw + 10px)',
                        top: '-10px',
                        left: '-10px',
                        background: '#000000dd',
                        display: 'flex',
                        zIndex: 100,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img src='./confirm.svg' style={{ width: '70px' }} />

                        <h4 className="h4 mb-1 mt-2 text-white">
                            Note
                        </h4>
                        <div > <p className='text-white' > {dialogmessage} </p> </div>

                        <div>
                            <button onClick={() => { setsavedialog('none'); setdialogmessage2(''); setmessage('') }} className="btn btn-success btn-submit w-100px mx-2">Okay</button>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />

        </>
    );
}


//export default Index;
export default SetcustomerPassword;