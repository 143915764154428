import React from 'react';
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import AdminApp from './Admin/Components/App';
import ScrollToTop from './Admin/Components/ScrollToTop';
import './fontawesome';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename='/' >
      <Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
          <ScrollToTop />
          <AdminApp />
				</PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
