import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Filter from '../Filters/Filter';
import useFetch from '../../../useFetch';
import { Button, Card, Dropdown, DropdownButton, Form, FormCheck, Image, Modal, Table } from 'react-bootstrap';
import Paginate from '../../../Paginate';
import { store } from '../../../redux/store';
import { removeUser } from '../../../redux/userSlice';
import Footer from '../../Footer';

const Index = () => {
    document.title = "Permits Update | Smart Trucking Services";
    
    let navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [listType, setListType] = useState(null);

    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/trucks-updates/'+(listType ?? 'active'));
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [sort, setSort] = useState({column: 'legal', type: 'asc'})
    const [apiUrl, setApiUrl] = useState(url);

    const filters = {
        action: apiUrl,
        method: 'GET',
        fields: [
            {
                label: 'Legal Name',
                type: 'input',
                name: 'legal',
            },
            {
                label: 'Plate No',
                type: 'input',
                name: 'plate',
            },
            {
                label: 'Unit No',
                type: 'input',
                name: 'unit',
            },
            {
                label: 'Vin No',
                type: 'input',
                name: 'vin',
            },
            {
                label: 'Emission Expiry Start',
                type: 'input',
                subType: 'date',
                name: 'emission_start',
            },
            {
                label: 'Emission Expiry End',
                type: 'input',
                subType: 'date',
                name: 'emission_end',
            },
            {
                label: 'State',
                type: 'select',
                name: 'state',
                values: [
                    { text: 'All', value: '' },
                    { text: 'OR', value: 'OR' },
                    { text: 'NY', value: 'NY' },
                    { text: 'KYU', value: 'KYU' },
                    { text: 'NM', value: 'NM' },
                    { text: 'CT', value: 'CT' },
                    { text: 'US Decal', value: 'US' },
                    { text: 'CARB', value: 'CRB' },
                ]
            },
            {
                label: 'State Type',
                type: 'select',
                name: 'state_type',
                values: [
                    { text: 'Select a type', value: '' },
                    { text: 'R', value: 'R' },
                    { text: 'NR', value: 'NR' },
                    { text: 'Active', value: 'Active' },
                    { text: 'Renew', value: 'Renew' },
                ]
            },
            // {
            //     label: 'Search Options',
            //     type: 'select',
            //     name: 'condition',
            //     values: [
            //         { text: 'All', value: '' },
            //         { text: 'New', value: 'new' },
            //         { text: 'Used', value: 'used' },
            //     ]
            // },
        ],
    };

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const { response, isLoading: fetchIsLoading, error: fetchError } = useFetch(apiUrl, sort);

    useEffect(() => {
        setItems(response?.data ?? []);
        setMeta(response?.meta ?? []);
        setCheckedItems([])
    }, [response])

    useEffect(() => {
        if (listType === null) return

        var newUrl = new URL(process.env.REACT_APP_BASE_API_URL+'/trucks-updates/'+(listType ?? 'active'));
        for (const [key, value] of searchParams.entries())
        {
            newUrl.searchParams.set(key, value);
        }

        setApiUrl(newUrl)
    }, [listType])

    useEffect(() => {
        for (const [key, value] of searchParams.entries())
        {
            url.searchParams.set(key, value);
        }
        setApiUrl(url);
    }, [searchParams])

    const handleSubmit = (status) => {
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        fetch(apiUrl, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token"),
            },
            body: JSON.stringify({'status': status, 'ids': checkedItems}),
        })
        .then(response => {
            if (!response.ok) {
                if (response.status == 401)
                {
                    store.dispatch(removeUser());
                    throw Error('Unauthorized access. Please retry after login');
                }
                else
                    throw Error('There was some error while updating, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);
                setItems(data?.data?.data ?? []);
                setMeta(data?.data?.meta ?? []);
                setCheckedItems([])
                setSort({column: 'legal', type: 'asc'})

                //setLegalName('');
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while updating, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    const toggleCheck = (id) => {
        setCheckedItems(current => {
            if (current.includes(id)) return current.filter(value => value != id)
            else return [...current, id]
        })
    }

    const handleSort = (column) => {
        setError(false);
        setSuccess(false);
        
        setSort(sort => {
            var type = 'asc'
            if (sort?.column == column)
            {
                type = sort?.type == 'asc' ? 'desc' : 'asc'
            }

            return {column: column, type: type}
        })

        setCurrentPage(0);
        var url = new URL(apiUrl);
        url.searchParams.delete('page');
        setApiUrl(url);
    }

    return (
        <div className="container-fluid">
            <Filter setApiUrl={setApiUrl} setCurrentPage={setCurrentPage} filters={filters} />
            
            <Card className="shadow mb-4">
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Permits Update</h5>
                    <div className='d-flex'>
                        <DropdownButton
                            variant="primary"
                            title={isLoading ? 'Updating...' : 'Update Selected'}
                            disabled={checkedItems.length == 0 || isLoading}
                            size={'sm'}
                            >
                            <Dropdown.Item onClick={() => handleSubmit('R')} className='font-weight-bold text-dark'>R Status</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSubmit('NR')} className='font-weight-bold text-danger'>NR Status</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSubmit('Active')} className='font-weight-bold text-success'>Active Status</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSubmit('Renew')} className='font-weight-bold text-warning'>Renew Status</Dropdown.Item>
                        </DropdownButton>
                        <Form.Select 
                            className="form-control ml-2"
                            value={listType ?? 'active'}
                            onChange={(e) => setListType(e.target.value)}
                            style={{ width: 'auto', fontSize: '14px', height: '30px', padding: '0px 8px' }}
                            >
                            <option value='all'>All Trucks</option>
                            <option value='active'>Active Trucks</option>
                            <option value='archive'>Archived Trucks</option>
                        </Form.Select>
                    </div>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { fetchIsLoading && <h5 className="text-center">Loading Data</h5> }

                    { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                    { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                    { !fetchError && !fetchIsLoading ? (
                        items?.length == 0 ? (
                            !url.searchParams.has('state') || !url.searchParams.has('state_type') ? <h5 className="text-center text-danger">Select state and type first</h5> : <h5 className="text-center text-danger">No data found</h5>
                        ) : (
                            <>
                                <div className='d-flex flex-wrap mb-2'>
                                    <Button onClick={() => setCheckedItems(items.map(value => value.id))} variant="success" className="btn-sm mr-2">Check All</Button>                                    
                                    <Button onClick={() => setCheckedItems([])} variant="warning" className="btn-sm mr-2">Uncheck All</Button>                                    
                                </div>

                                <Table responsive bordered hover striped>
                                    <thead>
                                        <tr>
                                            <th className="text-center"></th>
                                            <th onClick={() => handleSort('legal')} className={`${sort?.column == 'legal' ? 'sort-'+sort?.type : ''}`}>Legal Name</th>
                                            <th onClick={() => handleSort('phone')} className={`${sort?.column == 'phone' ? 'sort-'+sort?.type : ''}`}>Phone</th>
                                            <th onClick={() => handleSort('date')} className={`${sort?.column == 'date' ? 'sort-'+sort?.type : ''}`}>Date</th>
                                            <th onClick={() => handleSort('vin')} className={`${sort?.column == 'vin' ? 'sort-'+sort?.type : ''}`}>Vin#</th>
                                            <th onClick={() => handleSort('fleet')} className={`${sort?.column == 'fleet' ? 'sort-'+sort?.type : ''}`}>Fleet</th>
                                            <th onClick={() => handleSort('fleet_expiry')} className={`${sort?.column == 'fleet_expiry' ? 'sort-'+sort?.type : ''}`}>Fleet Expiry</th>
                                            <th onClick={() => handleSort('unit')} className={`${sort?.column == 'unit' ? 'sort-'+sort?.type : ''}`}>Unit No</th>
                                            <th onClick={() => handleSort('plate')} className={`${sort?.column == 'plate' ? 'sort-'+sort?.type : ''}`}>Plate No</th>
                                            <th onClick={() => handleSort('updated_by')} className={`${sort?.column == 'updated_by' ? 'sort-'+sort?.type : ''}`}>Updated By</th>
                                            <th onClick={() => handleSort('updated_date')} className={`${sort?.column == 'updated_date' ? 'sort-'+sort?.type : ''}`}>Updated Date</th>
                                            <th onClick={() => handleSort('or')} className={`${sort?.column == 'or' ? 'sort-'+sort?.type : ''}`}>OR</th>
                                            <th onClick={() => handleSort('ny')} className={`${sort?.column == 'ny' ? 'sort-'+sort?.type : ''}`}>NY</th>
                                            <th onClick={() => handleSort('kyu')} className={`${sort?.column == 'kyu' ? 'sort-'+sort?.type : ''}`}>KYU</th>
                                            <th onClick={() => handleSort('nm')} className={`${sort?.column == 'nm' ? 'sort-'+sort?.type : ''}`}>NM</th>
                                            <th onClick={() => handleSort('ct')} className={`${sort?.column == 'ct' ? 'sort-'+sort?.type : ''}`}>CT</th>
                                            <th onClick={() => handleSort('us')} className={`${sort?.column == 'us' ? 'sort-'+sort?.type : ''}`}>US Decal</th>
                                            <th onClick={() => handleSort('crb')} className={`${sort?.column == 'crb' ? 'sort-'+sort?.type : ''}`}>CARB</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item, index) => (
                                            <tr key={ item.id } onClick={() => toggleCheck(item.id)} style={{
                                                backgroundColor: checkedItems.includes(item.id) ? '#b4f9b3' : ''
                                            }}>
                                                <td className={`${checkedItems.includes(item.id) ? 'bg-success' : ''} text-white align-middle text-center`}>
                                                    <img src={checkedItems.includes(item.id) ? '/table-check.svg' : '/table-uncheck.svg'} style={{width: '20px'}} />
                                                </td>
                                                <td>
                                                    <Link to={`/customer-list/edit/${item.customer_id}`}>{ item.legal_name }</Link>
                                                    { !item.is_active ? <br /> : null }
                                                    { !item.is_active ? <span className={`badge badge-table badge-danger mr-1`}>Archived</span> : null }
                                                </td>
                                                <td>{ item.telephone }</td>
                                                <td>{ item.truck_date }</td>
                                                <td><Link to={`/truck-list/edit/${item.id}`}>{ item.vin_no }</Link></td>
                                                <td>{ item.irp_acc }</td>
                                                <td>{ item.fleet_expiry }</td>
                                                <td style={{ backgroundColor: '#01315A', color: '#ffffff' }}>{ item.unit_no }</td>
                                                <td>{ item.plat_no }</td>
                                                <td>{ item.last_updated_by }</td>
                                                <td>{ item.last_updated_ts }</td>
                                                <td className={`font-weight-bold text-${item.or_file_color}`}>{ item.or_file }</td>
                                                <td className={`font-weight-bold text-${item.ny_color}`}>{ item.ny }</td>
                                                <td className={`font-weight-bold text-${item.kyu_color}`}>{ item.kyu }</td>
                                                <td className={`font-weight-bold text-${item.nmwdt_color}`}>{ item.nmwdt }</td>
                                                <td className={`font-weight-bold text-${item.ct_color}`}>{ item.ct }</td>
                                                <td className={`font-weight-bold text-${item.ucr_color}`}>{ item.ucr }</td>
                                                <td className={`font-weight-bold text-${item.crb_color}`}>{ item.crb }</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />

                                <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
            <Footer/>
        </div>
    );
}
 
export default Index;