import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Table } from 'react-bootstrap';

const SafetyPrint = () => {
    document.title = "Safety Customer List | Smart Trucking Services";
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [columns, setColumns] = useState({
        cc1: false,
        cvor: false,
        ifta: false,
        kyu: false,
        ct: false,
        mc: false,
        nm: false,
        ny: false,
        or: false,
        scac: false,
        scacp: false,
        ucr: false,
        usdot: false,
    })

    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/safety-customers-print');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
        columns[key] = true
    }

    const [apiUrl, setApiUrl] = useState(url);

    const [items, setItems] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.customers ?? []);
    }, [response])

    useEffect(() => {
        if (items !== null && items?.length != 0) window.print();
    }, [items])

    return (
        <>
            { items === null ? <h2>Loading data, please wait...</h2> : (
                <Table bordered>
                    <thead>
                        <tr>
                        <th className="text-center">#</th>
                            <th>Legal Name</th>
                            <th>Trade Name</th>
                            <th>Customer Name</th>
                            <th>Telephone</th>
                            <th>Cell</th>
                            <th>Fax</th>
                            <th>NY Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                    { items?.map((item, index) => (
                            <tr key={ item.id }>
                                <td className="bg-dark text-white align-middle text-center">{ index + 1 }</td>
                                <td>{ item.legal_name }</td>
                                <td>{ item.trade_name }</td>
                                <td>{ item.telephone }</td>
                                <td>{ item.cp_first_name }</td>
                                <td>{ item.telephone }</td>
                                <td>{ item.cell }</td>
                                <td>{ item.fax }</td>
                                <td>{ item.email }</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </>
    );
}
 
export default SafetyPrint;